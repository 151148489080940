<template>
    <div class="home">
        <div class="container">
            <div class="row  bg">
                 

                <div class="col-12 row">
                    <div class="title" style="text-align:right; margin-top:30px; margin-bottom:30px;">{{obj.title}}</div>
                     
                   
                </div>

                <div class="col-12" style="position:relative;">
                    <img :src="beforeSrc[index]" class="img-responsive" style="width:100%;"  />
                    <div class="top-left">
                        <span class="fa-solid fa-arrow-left" style="color: #f6d477; margin-right:16px; "></span>
                        <a class="title back-arrow" href="/OurPortfolio">Our Portfolio</a>
                    </div>
                    <div class="bottom-right title">
                        <div>Before</div>
                    </div>
                </div>

                <div class="col-12 row" style="display:flex; margin:32px 0px; padding:0px 10px !important; ">
                    <div class="col-6 col-md-3 lightBg">
                        <div class="title text" style="">Completion Date:</div>
                        <div  class="text2">{{obj.completeDate}}</div>

                    </div>
                    <div class="col-6 col-md-3 lightBg">
                        <div class="title text">Property type :</div>
                        <div class="text2">{{obj.type}}</div>

                    </div>
                    <div class="col-6 col-md-3 lightBg">
                        <div class="title text">Price :</div>
                        <div class="text2">{{obj.price}}</div>

                    </div>
                    <div class="col-6 col-md-3 lightBg">
                        <div class="title text">Duration:</div>
                        <div class="text2">{{obj.duration}}</div>

                    </div>
                </div>

                <div class="col-12 desc">
                    {{obj.desc}}
                </div>
                
                <div class="col-12" style="position:relative;">
                    <div class="top-left2">                       
                        <div class="title" >After</div>
                    </div>
                    <div class="bottom-right2 title">
                        <div @click="nextPage">{{obj.next}}</div>
                        <span class="fa-solid fa-arrow-right" style="color: #f6d477; margin-left:16px; "></span>
                    </div>
                    <img :src="afterSrc[index]" style="width:100%;" class="img-responsive" />
                </div>
            </div>
          
            
 
        </div>
    </div>
</template>
 
<script>
    export default {
        name: 'Portfolio',
        data() {

            return {
                beforeSrc: [
                    require('../assets/images/LAKESHOREMELROSE/BEFORE.png'),
                    require('../assets/images/LAKESHOREAVENUE/BEFORE.png'),
                    require('../assets/images/GILBERT/BEFORE.png'),
                    require('../assets/images/FAIRLAWN/BEFORE.png'),
                    require('../assets/images/KIPLING/BEFORE.png'),
                    require('../assets/images/BRAEMAR/BEFORE.png')
                ],
                afterSrc: [
                    require('../assets/images/LAKESHOREMELROSE/AFTER.png'),
                    require('../assets/images/LAKESHOREAVENUE/AFTER.png'),
                    require('../assets/images/GILBERT/AFTER.png'),
                    require('../assets/images/FAIRLAWN/AFTER.png'),
                    require('../assets/images/KIPLING/AFTER.png'),
                    require('../assets/images/BRAEMAR/AFTER.png')
                ],

                max: 6,
                index: 0,
                obj: {
                    title: '',
                    index: this.$route.params.index,
                    completeDate: '',
                    type: '',
                    price: '',
                    duration: '',
                    desc: '',
                    next: ''
                }

            }
        },

        mounted() {
            this.index = this.$route.params.index;
            this.setView();
        },
        methods: {

            nextPage() {
                this.index++;
                this.index = this.index % this.max;
                this.setView();
            },
            setView() {
                console.log(this.index);


                if (this.index == 0) {
                    this.obj.title = "Lakeshore Melrose, Etobicoke";
                    this.obj.completeDate = "Feb 2020";
                    this.obj.type = "Residential";
                    this.obj.price = "$350,000";
                    this.obj.duration = "4 Months";
                    this.obj.desc = "We were recently hired by a young couple in Toronto that had a purchased a house that was a true \"fixer-upper\" in every sense of the word. It was an old house that needed a lot of work in order to make it more modern, and the couple also wanted to add an additional bedroom and ensuite bathroom so that they would have enough space when the time came to start a family. We worked our magic and gave the house a much more sophisticated feel!";
                    this.obj.next = 'Lakeshore Avenue, Etobicoke';
                } else if (this.index == 1) {
                    this.obj.title = "Lakeshore Avenue, Etobicoke";
                    this.obj.completeDate = "July 2020";
                    this.obj.type = "Residential";
                    this.obj.price = "$540,000";
                    this.obj.duration = "4 Months";
                    this.obj.desc = "There's nothing that gives us more pride than seeing the joy on a client's face when they finally walk into their lavish, newly renovated home. In this case, the original home was lacking space and was, as the client stated, a bit too \"cozy\" for their family. After our team was finished, we had completely transformed the layout of the house, giving it a more lavish open concept and freeing up plenty of space for family activities and storage.";
                    this.obj.next = 'Gilbert Avenue, Toronto';
                } else if (this.index == 2) {
                    this.obj.title = "Gilbert Avenue, Toronto";
                    this.obj.completeDate = "December 2020";
                    this.obj.type = "Residential";
                    this.obj.price = "$600,000";
                    this.obj.duration = "5 Months";
                    this.obj.desc = "At Outstanding Construction, we do more than simply renovate homes - we can also help you plan out your grandiose renovations and give you ideas that will make your home the absolute best that it can be! The above project was completed after we consulted with the clients and got a clear understanding of what they wanted, followed by our team presenting a few ideas that we felt would really make the house a true thing of beauty. As you can see, this collaborative effort paid off handsomely, with a previously drab house fully renovated into an extravagant dream home.";
                    this.obj.next = 'Fairlawn Avenue, Toronto';
                } else if (this.index == 3) {
                    this.obj.title = "Fairlawn Avenue, Toronto";
                    this.obj.completeDate = "May 2021";
                    this.obj.type = "Residential";
                    this.obj.price = "$450,000";
                    this.obj.duration = "5 Months";
                    this.obj.desc = "Bungalows - or single-story houses - are fairly ubiquitous in the Greater Toronto Area, and we often receive requests to build an upper addition onto these homes for families who are looking to grow or get a little extra space. The above bungalow was one that our team recently worked on, and after it was completed, it was almost a completely new house - our clients were thrilled with the exquisite final result!";
                    this.obj.next = 'Kipling Grove Court, Toronto';
                } else if (this.index == 4) {
                    this.obj.title = "Kipling Grove Court, Toronto";
                    this.obj.completeDate = "Feb 2022";
                    this.obj.type = "Residential";
                    this.obj.price = "$386,000";
                    this.obj.duration = "3 Months";
                    this.obj.desc = "A few months ago, we received a request from a client that we had worked with in the past when they wanted to renovate their newly-purchased home. This time around, they wanted to build an addition onto the back of their house so that they would have the room and facilities required to look after and care for their elderly, ill father. It's an honour to work on any project that our clients bring to us, but this one made us feel especially good - helping people is something that we love to do here at Outstanding Construction!";
                    this.obj.next = 'Braemar Avenue, Toronto';
                } else if (this.index == 5) {
                    this.obj.title = "Braemar Avenue, Toronto";
                    this.obj.completeDate = "September 2021";
                    this.obj.type = "Residential";
                    this.obj.price = "$275,000";
                    this.obj.duration = "4 Months";
                    this.obj.desc = "Occasionally, house renovations can be a necessity borne out of unexpected damage or accidents. The project above was a home that was severely damaged during a violent thunderstorm, and our clients were extremely nervous that their home would never be the same again. And in the end, they were right - because our team made it fit for a king and queen and better than ever before! A completely redesigned main floor and the addition of a back spare bedroom were the highlights of this job, one of our team's favourites.";
                    this.obj.next = 'Lakeshore Melrose, Etobicoke';
                }

            }

            

        }
        
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    @import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css'; 

    .bg {
        background: linear-gradient( rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.9) ), url('../assets/images/ouporfiliobg.jpg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        padding-top: 80px;
        padding-bottom: 30px;
    }
    .lightBg {
        background: linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ), #bdb76b;
    }

    .lightBg .text{font-size: 16px !important; padding: 16px 0px 16px 16px;}
    .lightBg .text2{font-size: 14px !important; color:white; padding:0 0 16px 16px;}

    .desc{color:#ccc; font-size:14px; line-height:26px; margin-bottom:32px;}
     
    .back-arrow{ color: #ccc !important; font-size:26px; text-decoration:none; display:block; margin:12px;}
    .back-arrow:hover{color: #f6d477 !important;}

     

    .top-left { position: absolute; top: 8px; left: 16px; display:flex; }
    .top-left span{font-size:20px; line-height:60px;}

    .bottom-right{position:absolute; bottom: 16px; right: 16px; font-size: 30px !important; color: #f6d477; line-height:60px;}


    .top-left2 { position: absolute; top: 32px; left: 32px;  }
   

    .bottom-right2{position:absolute; bottom: 16px; right: 16px; font-size: 30px !important; color: #f6d477; line-height:60px; display:flex; cursor:pointer;}
    .bottom-right2 span{font-size:20px; line-height:60px;}


    @media only screen and (max-width: 900px) {
      
         .top-left2 { position: absolute; top: 8px; left: 16px; display:flex; }
        .bottom-right2{font-size: 22px !important; line-height:50px;}
           .bottom-right2 span{font-size:20px; line-height:50px;}
    }
    @media only screen and (max-width: 600px) {
        .back-arrow{font-size:20px;}
        .top-left span{font-size:16px; line-height:55px;}

        .bottom-right {
            font-size: 20px !important; line-height: 40px;
        }
         
         .top-left2 { position: absolute; top: 8px; left: 16px; display:flex; }
          .bottom-right2{font-size: 14px !important; line-height:40px;}
          .bottom-right2 span{font-size:14px; line-height:40px;}
    }
</style>

