<template>
    <div class="home">
        <div class="container">

            <div style="display:none;">
                <a :href="`/files/The5MistakesPeopleMakeRemodel.pdf`" id="downloadBook" download>Download My File</a>
            </div>
            <div class="row bg">
                <div class="col-12">

                    <nav class="navbar navbar-expand-lg navbar-light bg-light">
                        <div class="container">
                            <a class="logo" href="/"></a>

                            <div class="menu">
                                <a class="info nav-text" href="/AboutUs">
                                    About us
                                </a>
                                <a class="info nav-text" href="/WhatWeDo">
                                    What we do
                                </a>
                                <a class="info nav-text" href="/OurProcess">
                                    Our Process
                                </a>
                                <a class="info nav-text" href="/OurPortfolio">
                                    Our portfolio
                                </a>
                                <a class="info nav-text" href="/Contact">
                                    Contact
                                </a>
                            </div>
                            <div class="menu2">
                                <button class="navbar-toggler" type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#navbarSupportedContent"
                                        @click="visible=!visible">
                                    <span class="material-icons-outlined gold icon-middle" style="font-size:36px;">menu</span>

                                </button>
                                <div class="navbar-collapse" :class="!visible?'collapse':''" id="navbarSupportedContent">
                                    <ul class="navbar-nav">
                                        <li class="nav-item">
                                            <router-link to="/AboutUs" class="nav-link" @click="visible=!visible">About Us</router-link>
                                        </li>
                                        <li class="nav-item">
                                            <router-link to="/WhatWeDo" class="nav-link" @click="visible=!visible">What We Do</router-link>
                                        </li>
                                        <li class="nav-item">
                                            <router-link to="/OurProcess" class="nav-link" @click="visible=!visible">Our Process</router-link>
                                        </li>
                                        <li class="nav-item">
                                            <router-link to="/OurPortfolio" class="nav-link" @click="visible=!visible">Our Portfolio</router-link>
                                        </li>

                                        <li class="nav-item">
                                            <router-link to="/Contact" class="nav-link" @click="visible=!visible">Contact</router-link>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </nav>

                </div>

            </div>
         
            <div class="row">

                <div class="col-12 title pd40" style="max-width:800px; margin:auto;">
                    Learn the most common mistakes made in the home renovation business in 2023 and how to prevent them from Outstanding Construction.

                </div>
                <div class="col-12 text-center"><img src="../assets/images/LINES.png" style="width:80%; height:auto; max-width:500px; margin: 20px auto;" /></div>
            </div>

            <form class="row" style="height:100%; width:90%; margin:20px auto; max-width:800px;">

                <div class="col-6 left-bg">
                    <img src="../assets/images/bookcover.png" style="width:80%; max-width:450px; margin:40px 0;" />
                </div>

                <div class="col-6 row " style="background-color:white; padding:0px; text-align:left;">
                    <div class="col-12 title text-center" style="background-color: #D5BD7A; color:black; padding:10px; font-size:20px;">Please fill out the information below to get access to the free eBook</div>

                    <div class="col-12" style="text-align:left;">
                        <label class="title" style="font-size:16px; color:black;">First Name</label>
                        <input type="text" class="form-control" v-model="info.firstname">
                    </div>

                    <div class="col-12" style=" text-align: left;">
                        <label class="title" style="font-size:16px; color:black;">Last Name</label>
                        <input type="text" class="form-control" v-model="info.lastname">
                    </div>
                    <div class="col-12" style=" text-align: left;">
                        <label class="title" style="font-size:16px; color:black;">Email Address</label>
                        <input type="text" class="form-control" v-model="info.email">
                    </div>
                    <div class="col-12" style=" text-align: left;">
                        <label class="title" style="font-size:16px; color:black;">Phone Number</label>
                        <input type="text" class="form-control" v-model="info.phone">
                    </div>
                    <div class="col-12">

                        <label for="email" style="display:block; text-indent:-15px; padding-left:15px;">  <input type="checkbox" id="email" v-model="info.contactByEmail" />contact me by Email</label>
                    </div>
                    <div class="col-12">

                        <label for="phone" style="display:block; text-indent:-15px; padding-left:15px;">  <input type="checkbox" id="phone" v-model="info.contactByPhone" />contact me by Phone number</label>
                    </div>
                    <div class="col-12 text-center">
                        <img src="../assets/images/btn_getbook.png" style="width:90%; max-width:270px; margin:auto; cursor:pointer;" @click="save" />
                    </div>

                    <br /><br />
                </div>


            </form>

        </div>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        data() {
            return {
                info: {
                    firstname: "",
                    lastname: "",
                    email: "",
                    phone: "",
                    contactByEmail: true,
                    contactByPhone: true,
                    FromContact: false,
                    Message: ""
                }
            }
        },
        methods: {
            save() {

                let _this = this;
                var url = '/api';
                axios.post(url, _this.info).then(function (response) {

                    if (response.data.Code == 0) {
                        _this.info = {
                            firstname: "",
                            lastname: "",
                            email: "",
                            phone: "",
                            contactByEmail: true,
                            contactByPhone: true,
                            FromContact: false,
                            Message: ""
                        }
                        //_this.$emit('save');

                        document.getElementById("downloadBook").click();

                    } else {

                        alert(response.data.Msg);
                    }

                })
                    .catch(function (error) {
                        console.log(error);
                    });


            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


    .left-bg {
        background: linear-gradient( rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.9) ),url('../assets/images/popupleftbg.png') no-repeat;
        background-position: center;
        background-size: cover;
        height: 100% !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .bg {
        background: url('../assets/images/bg1.png') no-repeat;
        background-position: center;
        background-size: cover;
        padding-top: 80px;
        padding-bottom: 30px;
    }

    .form-control {
        border: 2px black solid;
        border-radius: 6px;
        height: 34px;
    }

    input[type='checkbox'] {
        width: 15px;
        height: 15px;
        padding: 0;
        margin-right: 8px;
        vertical-align: middle;
        position: relative;
        top: -1px;
    }

    .col-12 {
        height: max-content;
    }
    

</style>

