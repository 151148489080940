<template>
    <div class="home">
        <div class="container">
            <div class="row  bg">
           
                <div class="col-12 text-center title pd40 moving-div bottom-to-top b2t">
                    What We do
                </div>
                <div class="col-12 info pd30 moving-div bottom-to-top b2t" style="padding-top: 0px;">
                    Outstanding Construction is premier construction provider based in the Greater Toronto Area that specializes in whole-home renovations, additions, and laneway housing.
                    Our team is proud to serve clientele throughout the GTA, and each team member brings a wealth of knowledge and skills to the table. After all, we're called 'Outstanding Construction' for a reason!
                </div>

            </div>



            <div class="row bg2">
                <div class="col-12 col-md-6  title pd40 moving-div bottom-to-top b2t">
                    Tacking any challenge

                    <div class="col-12 info moving-div bottom-to-top b2t" style="padding-top: 16px; text-align:justify;">
                        When you work with our team, you can rest assured that your project - no matter the size - will be in very capable hands.
                        We adhere to very stringent construction methods from start to finish, and you'll get more than just the bare minimum, as we always strive to go above and beyond for each and 
                        every one of our clients. Our philosophy is to use only the best tools, work with only the most luxurious and opulent materials, and ensure that all codes and regulations are
                        followed to the letter. Whether it's building a new addition or completing a full whole-home renovation from the inside out, we won't skimp on any of the details.
                    </div>
                </div>
                 
                

                <div class="col-md-6 col-12 text-center moving-div bottom-to-top b2t" style="padding-top: 32px;">
                    <img src="../assets/images/WHAT-WE-DO-IMAGES.png" style="width:100%; height:auto; max-width:360px;" class="img-responsive" />
                </div>

                <div class="col-12  moving-div b2t  title pd40">
                    What sets us apart
                </div>
                <div class="col-12 info pd30 moving-div b2t" style="padding-top: 0px;">
                    It's our goal - nay, our purpose - to help our clients achieve their vision for their dream home, ultimately providing a lavish and magnificent
                    living space that your family and future generations can enjoy for years and decades to come. When you hire Outstanding Construction, our entire team of experts
                    is here for you - from our experienced architects and engineers, to our skilled designers, to the construction team itself, we'll work closely with you to 
                    ensure that your happy with our progress from beginning to end.
                </div>
                <div class="col-12 text-center moving-div b2t" style="padding-top: 0px;">
                    <img src="../assets/images/WHAT-WE-DO2.png" style="width:100%; height:auto; max-width:720px;" class="img-responsive" />
                </div>
            </div>



        </div>
    </div>
</template>

<script>
    export default {
        name: 'What We Do',
        data() {
            return {
                divs: []
            }

        },
        mounted() {

            window.addEventListener('load', () => {
                this.divs = document.getElementsByClassName("moving-div");
                window.addEventListener("scroll", this.handleScroll);


            });

        },
        beforeDestroy() {
            window.removeEventListener("scroll", this.handleScroll);
        },
        methods: {
            handleScroll() {
                const windowHeight = window.innerHeight;
                //const scrollY = window.scrollY;

                Array.from(this.divs).forEach((div) => {
                    const rect = div.getBoundingClientRect();
                    //console.log("height", rect.top + ", " + rect.bottom, windowHeight + ", " + (rect.bottom - rect.top));
                    if (
                        rect.top <= windowHeight &&
                        rect.bottom <= windowHeight + rect.bottom - rect.top
                    ) {

                        if (div.classList.contains("b2t") && !div.classList.contains("bottom-to-top")) {
                            //console.log("`````````````````````````````````effect!`````````````````````````");
                            div.classList.add("bottom-to-top");
                        } else if (div.classList.contains("r2l") && !div.classList.contains("right-to-left")) {
                            div.classList.add("right-to-left");
                        } else if (div.classList.contains("t2b") && !div.classList.contains("top-to-bottom")) {
                            div.classList.add("top-to-bottom");
                        } else if (div.classList.contains("l2r") && !div.classList.contains("left-to-right")) {
                            div.classList.add("left-to-right");
                        }


                    }
                });
            },
        }

    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!-- background: url('../assets/images/whatwedobg.png') no-repeat; -->
<!-- background: url('../assets/images/whatwedobg2.png') no-repeat; -->
<style scoped>

    .bg {
        background-position: center;
        background-size: cover;
   
        padding-bottom: 30px;
    }

    .bg2 {
        background-position: center;
        background-size: cover;
    }

    .pd40 {
        padding: 40px;
    }

    .pd30 {
        padding: 30px;
    }

    hr {
        height: 2px;
        margin-top: 16px;
        margin-bottom: 16px;
        background-color: white;
        width: 100%;
    }
</style>

