<template>
    <div id="app">

        <headerComp />
        <router-view></router-view>

        <footerComp v-if="showFooter" />
    </div>
    
</template>

<script>
    import headerComp from './components/headerComp.vue';
    import footerComp from './components/footerComp.vue';
    export default {
        name: 'app',
        components: {
            footerComp, headerComp
        },
        computed: {
            showFooter() {
                // Check if the current route is the video page
                //console.log("Router name", this.$route);
                //return this.$route.href !== '/5mistakevideo';
                return true;
            }
        }
    };
</script>

<style>
</style>

