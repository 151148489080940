<template>
    <div class="home">
        <div class="fullscreen">

            <div style="display:none;">
                <a :href="`/files/The5MistakesPeopleMakeRemodel.pdf`" id="downloadBook" download>Download My File</a>
            </div>

            <div class="home-bg paged" style="min-height: 800px !important;" >

                <div class="row container">
                    <div class="col-12 moving-div bottom-to-top b2t text-center" style="margin:auto;">

                        <div class="title home-title" style="margin-top: 120px !important;">
                            Transform Your Living Space.
                        </div>

                        <div class="info white text-center" style="margin-top: 80px !important;">
                            High End Toronto Home Renovations and Additions with Premium Craftsmanship as Standard
                        </div>

                        <div class="schedule-call"  ></div>

                    </div>

                    <div class="col-12 row" style="margin-top:50px;">
                        <div class="col-12 col-md-4 moving-div bottom-to-top b2t">
                            <div class="text-center title gold" style="margin-top:16px; font-size: 24px; line-height: 30px;">On-Time Project Completion</div>
                        </div>
                        <div class="col-12 col-md-4 moving-div bottom-to-top b2t">
                            <div class="text-center title gold" style="margin-top: 16px; font-size: 24px; line-height: 30px; ">On-Budget Delivery</div>
                        </div>
                        <div class="col-12 col-md-4 moving-div bottom-to-top b2t">

                            <div class="text-center title gold" style="margin-top: 16px; font-size: 24px; line-height: 30px; ">Constant Communication</div>
                        </div>
                    </div>
                    
                </div>



            </div>

            <div class="black-bg" >

                <div class="row container paged" style="padding-top:80px;">
                     
                    <div class="col-12 moving-div bottom-to-top b2t text-center" style="margin:auto; margin-top:50px; margin-bottom:50px;">

                        <div class="title home-title">
                            But Are You Fearful of What Lies Ahead?
                        </div>

                    </div>

                    <div class="col-12 col-md-6 moving-div bottom-to-top b2t" style="padding: 16px;">
                        <div class="white">
                            Problem is, the construction and renovation industry suffers from a reputation for half-finished, over-budget projects that are plagued by a lack of communication, reliability and timeliness.
                            <br />
                            90% of all projects throw up unforeseen curve-balls. Don't believe anyone who promises you no surprises!
                        </div>

                    </div>
                    <div class="col-12 col-md-6 moving-div bottom-to-top b2t" style="padding: 16px;">
                        <ul class="white">
                            <li> Contractors can turn out to be unreliable. </li>
                            <li> Budgets can run way over. </li>
                            <li> Renovation or addition projects can remain unfinished. </li>
                            <li> There's a lack of timely or ongoing communication from the contractor. </li>
                        </ul>
                    </div>

                    <div class="col-12">
                        <div class="schedule-call" ></div>
                    </div>

                </div>

            </div>

            <div class="home-bg2">

                <!--Outstanding Construction Means Trustworthy, Transparent & Top-Tier Renovation Services-->
                <div class="row container paged">

                    <div class="col-12 moving-div bottom-to-top b2t text-center">

                        <div class="title home-title" style="max-width: 800px; margin:auto; margin-top:120px !important">
                            Outstanding Construction Means Trustworthy, Transparent & Top-Tier Renovation Services
                        </div>

                    </div>

                    <div class="col-12 row moving-div bottom-to-top b2t" style="border: 1.5px solid #e6a502; border-radius: 5px; margin:50px 0; padding:16px;">
                        <div class="col-6 col-md-3">
                            <div class="title gold" style="font-size:20px; line-height:24px; margin: 8px;">
                                Premium Quality<br /> Craftsmanship
                            </div>
                            <div class="info white text-center">We elevate every project above typical market standards</div>
                        </div>

                        <div class="col-6 col-md-3">
                            <div class="title gold" style="font-size:20px; line-height:24px; margin: 8px;">
                                Dedicated & Frequent <br />Communications
                            </div>
                            <div class="info white text-center">We ensure the client is always informed and involved.</div>
                        </div>

                        <div class="col-6 col-md-3">
                            <div class="title gold" style="font-size:20px; line-height:24px; margin: 8px;">
                                On-Time <br />Project Completion
                            </div>
                            <div class="info white text-center">We offer assurances unmatched by competitors, wherever possible.</div>
                        </div>

                        <div class="col-6 col-md-3">
                            <div class="title gold" style="font-size:20px; line-height:24px; margin: 8px;">
                                On-Budget Project<br /> Completion
                            </div>
                            <div class="info white text-center">We don't go over-budget without coming to you with solutions</div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="schedule-call"></div>
                    </div>

                </div>
            </div>

            <div class="moving-div bottom-to-top b2t black-bg" style="padding-bottom:48px;">
                <div class="row container paged" >

                    <div class="col-12  text-center" style="margin-top:50px;">

                        <div class="title home-title" style="max-width: 800px; margin: 60px auto !important; ">
                            Here's How We Work With You
                        </div>

                    </div>

                    <div class="col-12 col-md-4 text-center " style="padding: 0 32px;">
                        <img src="../assets/images/new/bg_number1.png" width="70" style="margin:20px auto;" />
                        <div class="title gold" style="font-size: 24px; line-height: 30px; margin: 8px;">
                            Onsite Meeting
                        </div>
                        <div class="info white text-center">Together, we'll discuss your new living space's ideal functionality, plus design ideas, with a realistic assessment of what's doable on your budget.</div>

                    </div>
                    <div class="col-12 col-md-4 text-center" style="padding: 0 32px;">
                        <img src="../assets/images/new/bg_number2.png" width="70" style="margin:20px auto;" />
                        <div class="title gold" style="font-size: 24px; line-height: 30px; margin: 8px;">
                            Proposal & Quotation
                        </div>
                        <div class="info white text-center">We'll outline a scope of work, with timelines and budgeting, so you can proceed to contract confident in a great outcome.</div>

                    </div>
                    <div class="col-12 col-md-4 text-center" style="padding: 0 32px;">
                        <img src="../assets/images/new/bg_number3.png" width="70" style="margin:20px auto;" />
                        <div class="title gold" style="font-size:24px; line-height:30px; margin: 8px;">
                            Deliver the Project
                        </div>
                        <div class="info white text-center">With contracts signed, designs completed and permits acquired, we go to work to get you outstanding results.</div>

                    </div>

                    <div class="col-12" style="margin-top:50px; margin-bottom: 50px;">
                        <div class="schedule-call"></div>
                    </div>


                    

                </div>

                <div class="row container paged" style="padding-top: 80px; padding-bottom: 80px;">

                    <div class="col-12 col-md-6 text-center">

                        <div style="margin: 20px auto; max-width: 90%; opacity: 1; transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;">
                            <img src="../assets/images/new/img_home1.png" style="max-width: 100%;" />
                        </div>


                    </div>

                    <div class="col-12 col-md-6">

                        <div class="title home-title" style="text-align:left;">
                            Why Outstanding<br /> Construction?
                        </div>
                        <div class=" white" style="padding:16px; text-justify:unset;">
                            We get it and we've got your back: we've seen bad experiences happen over and over again in our decades in the construction industry.
                            <br />  <br />
                            That's why at Outstanding Construction, we have always set realistic expectations, budgets and timelines upfront, brought the inevitable problems that arise to your immediate attention, with solutions and a contingency ready to solve them, for a seamless renovation experience, with quality and client satisfaction at the forefront of everything we do.
                        </div>

                        <div class="schedule-call" style="text-align:left; margin-left:16px;"></div>
                    </div>

                </div>
            </div>

            <div class="home-bg3 paged">
                <div class="row moving-div bottom-to-top container" style="padding: 30px;">
                    <div class="col-12">
                        <div class="title home-title">
                            Some Outstanding Successes!

                        </div>

                        <div class="schedule-call" ></div>

                    </div>

                    <div class="col-12 col-md-6">
                        <div class="review-box">
                            <div class="review-title">CHARLES & CHRISTINE DEMORE</div>
                            <div class="review-subtitle" @click="showReview1">
                                Charles and Christine had bought a house five or six years previously - a house with "good bones" and liveable -
                                but were aware that all the renovations needed - main floor "flow", kitchen "falling apart', master bathroom,
                                full HVAC and electrical and more - had piled up... <span class="gold">Read more</span>
                            </div>

                            <div class="review-info" v-show="review1">

                                Charles and Christine had bought a house five or six years previously - a house with "good bones" and liveable -
                                but were aware that all the renovations needed - main floor "flow", kitchen "falling apart', master bathroom,
                                full HVAC and electrical and more - had piled up. Knowing it would be better to get everything done at the same time,
                                the couple set themselves up for a whole house renovation: they hired an architect to draw up plans, get approvals and permits,
                                but then had difficulty finding a contractor.  In an environment where they all seemed to have too much work, or simply
                                didn't respond at all, a colleague of Charles', who had had a major renovation done herself, asked the couple over to see the
                                quality of the work and recommended Ardit and Outstanding Construction.  This was to be a six-month project,
                                and the couple moved out in December 2022.  From the very beginning, Ardit helped them see what would be good uses
                                of their space and what the scope could - or should - encompass: all of the above, but also basement underpinning and a
                                steel beam, a small office reconfiguration, new patio door off the dining room, moving the laundry room upstairs, replumbing,
                                drywall and even a larger closet for their daughter.  Again, from the beginning and throughout the work, Ardit was quick and
                                available to brainstorm (practical but also creative, like a forest mural for their daughter's room) and set out milestones for
                                scope and budget that he rigidly fulfilled. Charles' shift work meant that he could be around for making decisions along the way
                                incredibly quickly. "Ardit helped us be honest about the realities of the project and wouldn't let us ignore or move past what we
                                needed to address". Indeed in the early demolition stages, Ardit had reached out to a (new) building inspector, who showed up three
                                days earlier than scheduled(on purpose) and said, "You're doing everything exactly the right way". The result?  The couple were able
                                to move back in, on schedule, to a fully functional home, laid out and designed, confident in the house they now have, its flow and
                                its infrastructure.  What could well be a stressful process, Charles and Christine found to be "relatively painless" (with Charles
                                saying that any delays were his fault, not Ardit's) and with Ardit at all times more than patient and trustworthy.

                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-6">
                        <div class="review-box">
                            <div class="review-title">CONNIE</div>
                            <div class="review-subtitle" @click="showReview2">
                                Connie's daughter, a teacher, had lived in a succession of Toronto apartments. She was seeking more space and a 
                                bit less of the city's hustle and bustle. The two women looked around and found an older home in 
                                a quieter neighborhood... <span class="gold">Read more</span>
                            </div>

                            <div class="review-info" v-show="review2">

                                Connie's daughter, a teacher, had lived in a succession of Toronto apartments. She was seeking more space and a bit less of the city's hustle and bustle. The two women looked around and found an older home in a quieter neighborhood.

                                The problem was, the property needed quite a bit of work.  They hadn't realized the foundation had fractures: they would need to raise the house, e-run and upgrade the wiring, make a roof line change and rebuild the old garage that had been damaged by fire.

                                On top of all this, this was the period of the pandemic shutdown, so getting hold of city inspectors was proving to be frustrating.

                                Ardit, who had been working on a project in the same neighborhood, had left his card, and Connie and her daughter took him up on his offers of advice and brought him on board.

                                Ardit took on the lengthy and complicated business of dealing with city inspectors, spending "many a day" at City Hall, refusing to be "put off' in getting answers

                                Beyond all that, Ardit worked collaboratively with Connie and her daughter, giving "A and B" options for the interior so they could make informed decisions: opening it up "top to tail", putting in a main floor powder room, kitchen subflooring and cabinetry.

                                Connie deems this major project an overall success. Again, Ardit went to bat with the "smart home" people, and rectified any minor deficiencies quickly.  Connie's daughter is happy in her thoroughly renovated home (but not so much the noisy neighbor)!

                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-6">
                        <div class="review-box">
                            <div class="review-title">JENNY</div>
                            <div class="review-subtitle" @click="showReview3">
                                Jenny and her husband are only the third owners of a100-year-old home. They  bought for less than the comparable 
                                prices on their street, knowing that a lower offer would enable their budget to do the major work the house 
                                needed... <span class="gold">Read more</span>
                            </div>

                            <div class="review-info" v-show="review3">
                                Jenny and her husband are only the third owners of a100-year-old home. They  bought for less than the comparable prices on their street, knowing that a lower offer would enable their budget to do the major work the house needed.

                                But there were a number of complicating factors.  Jenny and her husband didn't want to do the work themselves, in opening up the main floor, and they wanted the work done in the six weeks between closing and their move-in date, to not  be living in a construction zone.

                                Several contractors told them their time-frame was not realistic. But Ardit said he could do the work in the time allotted, within their budget. "Is this too good to be true?" they wondered.

                                How did he do it? With meticulous planning and scheduling. Working floor by floor, the renovations were done in sequence, often getting different things done at the same time, bringing in the different trades at different times, doing the right things first and, most importantly, bringing the couple in on continuous decision-making.

                                Jenny says that Ardit was consultative  and respectful (not always, she feels, the case with contractors and female clients!).  They got their full renovations done on time, with great success and a few extras. A recess for a microwave proved to be impossible so Ardit designed a small nook for glass shelving and stemware, a feature that many of their guests admire.

                                renovating the bathroom, he offered underfloor heating within the scope of work.

                                Overall, Jenny feels that Ardit went above and beyond: great quality work, within the six-week timeframe, move-in ready indeed.  Mission accomplished!

                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-6">
                        <div class="review-box">
                            <div class="review-title">HEATHER</div>
                            <div class="review-subtitle" @click="showReview4">
                                Heather needed to do a full bathroom renovation - a "consolidation" to replace what was old, 
                                dated and falling apart (such as 30-year-old windows and failing tilework) with a bathroom that 
                                would be comfortable, modern and stylish... <span class="gold">Read more</span>
                            </div>

                            <div class="review-info" v-show="review4">

                                Heather needed to do a full bathroom renovation - a "consolidation" to replace what was old, dated and falling apart (such as 30-year-old windows and failing tilework) with a bathroom that would be comfortable, modern and stylish.

                                But the footprint was small, and this room needed to serve as the house's master bathroom for the whole family, so somehow more space and more storage was needed.

                                Heather felt she had a strong vision for what she wanted, aesthetically speaking, but she really appreciated that Ardit respected her wishes, "gracefully directing" her when her ideas were too impractical, bringing his knowledge of what's current to the project (modern but not too modern) and continuously clarifying what was going to happen throughout.

                                A super-smooth process was enhanced by Ardit bringing his experience in opening up old houses from his many past renovations, knowing what surprises might crop up but also what the best solutions might be.
                                Ardit took Heather's worries about the renovation away, and was readily available with the connections and support needed as problems arose, with people at hand to fix them. "Hope for the best-case scenario, but be ready for the unforeseen".

                                In all, a full redesign of the master bathroom took over some outside closet space and wall storage to incorporate it into the new space - Heather got her modern fixtures, her cabinetry and the bathroom storage she wanted in her bathroom.  Very happy with the result.

                            </div>
                        </div>
                    </div>

           

                </div>

            </div>

            <div class="paged">
                <div class="row moving-div bottom-to-top container" style="background-color:black">
                    <div class="col-12 title moving-div b2t" style=" margin:80px 0px; ">
                        What We Offer
                    </div>

                    <div class="col-12 col-md-4 text-center">
                        <img src="../assets/images/new/img_home2.png" style="margin: 24px 16px 0px 16px; max-width:90%; " />
                        <a class="title text-under-img" href="/WhatWeOffer">Bathroom Renovations</a>
                    </div>
                    <div class="col-12 col-md-4 text-center">
                        <img src="../assets/images/new/img_home3.png" style="margin: 24px 16px 0px 16px; max-width: 90%; " />
                        <a class="title text-under-img" href="/WhatWeOffer">Basement Renovations</a>
                    </div>
                    <div class="col-12 col-md-4 text-center">
                        <img src="../assets/images/new/img_home4.png" style="margin: 24px 16px 0px 16px; max-width: 90%; " />
                        <a class="title text-under-img" href="/WhatWeOffer">Kitchen Renovations</a>
                    </div>
                    <div class="col-12 col-md-4 offset-md-2 text-center ">
                        <img src="../assets/images/new/img_home5.png" style="margin: 24px 16px 0px 16px; max-width: 90%; " />
                        <a class="title text-under-img" href="/Additions">Additions</a>
                    </div>
                    <div class="col-12 col-md-4 text-center">
                        <img src="../assets/images/new/img_home6.png" style="margin: 24px 16px 0px 16px; max-width: 90%; " />
                        <a class="title text-under-img" href="/Laneway">Laneway Housing</a>
                    </div>

                    <div class="col-12">
                        <div class="schedule-call"  ></div>
                    </div>



                </div>
            </div>

            <div class="">
                <div class="row container">
                    <div class="col-12">

                        <div style="color:white; " class="flex text-center">
                            <a style="margin: 16px 8px; display:inline-block;">
                                <img src="../assets/images/Baeumler-approved-logo.png" style="height:149px; width:auto;" />
                            </a>

                            <a style="margin: 16px 8px; display: inline-block;">
                                <img src="../assets/images/renofind-certified.png" style="height:70px; width:auto; margin-left:16px;" />
                            </a>

                            <a style="margin: 16px 8px; display: inline-block; height: 70px; vertical-align: middle;">
                                <iframe border="0" frameborder="0" style="border: 0; height:70px; width:293px;" src="https://seal-mwco.bbb.org/frame/blue-seal-293-61-whitetxt-bbb-1407283.png?chk=53B66FA7BF"></iframe>
                            </a>

                            <a style="margin: 16px 8px; display: inline-block; height: 65px;">
                                <img src="../assets/images/new/apb_logo.jpg" style="height:123px; width:auto; margin-left:16px;" />
                            </a>

                        </div>


                    </div>

                </div>

            </div>


            <div class="row moving-div bottom-to-top" style="padding-top: 60px;">
                <div class="row container paged" style="align-items:center;">
                    <div class="col-12 col-md-6">
                        <div class="info" style="text-align:initial;">
                            "I use Outstanding Construction both before and after sales. Ardit completely understands the urgency in getting a home prepared for market. He can be counted on for everything from minor repairs to solving major roofing problems. And when a buyer needs renovations large or small after purchase, I don't hesitate to recommend Ardit, who will ensure his clients are happy with the results.
                            <br /><br />
                            Ardit is very reliable, offers lots of alternatives, gets things done in a timely manner and responds very well - his team shows up when they say they will, they stick to the schedule and follow through".
                        </div>
                        <div class="title" style="margin-left: 0px; text-align:left; font-size:26px;">
                            Desmond Brown, ReMax Hallmark
                        </div>
                        <div class="info" style="text-align:initial;">
                            www.inthe6ixrealestate.com
                        </div>
                    </div>

                    <div class="col-12 col-md-6 text-center">
                        <img src="../assets/images/new/map.png" style="max-width:90%;" />
                    </div>

                </div>

            </div>

            <div class="row moving-div bottom-to-top paged" style="padding: 60px 0px; ">
                <div class="row container">
                    <div class="col-12 title">
                        Outstanding Construction Means<br /> Getting the Results You Planned For
                    </div>
                    <div class="col-12 col-md-6">

                        <div class="info" style="text-align:center; max-width:300px; margin:40px auto;">
                            You can stop worrying about half-finished jobs going over budget and over timelines, shoddy work well below your expectations, and a lack of communication, not just with you but also with neighbor's who may be affected by the construction work.
                        </div>
                    </div>

                    <div class="col-12 col-md-6">
                        <div class="info" style="text-align:center; max-width:300px; margin:40px auto;">
                            Instead, start looking forward to a fully collaborative approach, quality craftsmanship resulting in the renovation or addition you desired, a respectful and easy-to-deal-with project manager and team, always with upfront communications and the set budgets and timelines respected. Our aim is to exceed our customers' expectations!
                        </div>
                    </div>

                    <div class="schedule-call"></div>
                </div>

            </div>

            <div class=" moving-div bottom-to-top paged" style="padding: 60px 0px; ">
                <div class="row container">
                    <div class="col-12 title" style="margin-bottom:100px;">
                        FAQs
                    </div>
                    <div class="col-12 col-md-6" >

                        <div class="readmore-border">
                            <div class="readmore" @click="toggleSpan1">
                                <div class="content"> What do we need Toronto permits for? </div>
                                <div class="button-container">
                                    <button class="yellow-arrow" :class="{'arrow-down': !span1Clicked, 'arrow-left': span1Clicked}">
                                        <span>&#10095;</span>
                                    </button>
                                </div>
                            </div>
                            <div v-if="span1Clicked" style="margin-bottom:20px;">
                                <div class="info" style="text-align:left;">
                                    You must get a building permit when you make renovations, repairs or add to a building; when you change the use of abuilding, excavate or construct a foundation, install or repair a sewer system, or construct any new building more than ten square meters in area - or bring another structure, such as a mobile home, onto your property.
                                </div>

                            </div>
                        </div>


                        <div class="readmore-border">
                            <div class="readmore" @click="toggleSpan3">
                                <div class="content"> How do we obtain the permits we need? </div>
                                <div class="button-container">
                                    <button class="yellow-arrow" :class="{'arrow-down': !span3Clicked, 'arrow-left': span3Clicked}">
                                        <span>&#10095;</span>
                                    </button>
                                </div>
                            </div>
                            <div v-if="span3Clicked" style="margin-bottom:20px;">
                                <div class="info" style="text-align:left;">
                                    When you apply (to the City), you'll need to attach drawings, plans and other documents as specified by city staff.  Let us do the permit process for you, once we've finalized designs, drawings, materials and labour costs.
                                </div>

                            </div>
                        </div>

                        <div class="readmore-border">
                            <div class="readmore" @click="toggleSpan5">
                                <div class="content"> What professional/ industry licenses do you hold? </div>
                                <div class="button-container">
                                    <button class="yellow-arrow" :class="{'arrow-down': !span5Clicked, 'arrow-left': span5Clicked}">
                                        <span>&#10095;</span>
                                    </button>
                                </div>
                            </div>
                            <div v-if="span5Clicked" style="margin-bottom:20px;">
                                <div class="info" style="text-align:left;">
                                    In addition to the accreditations on our website, we hold a City of Toronto Building License, and each of our trades (plumbing, electrical etc.) has their own master license.
                                </div>

                            </div>
                        </div>

                    </div>

                    <div class="col-12 col-md-6">
                        <div class="readmore-border">
                            <div class="readmore" @click="toggleSpan2">
                                <div class="content"> Can you provide references? </div>
                                <div class="button-container">
                                    <button class="yellow-arrow" :class="{'arrow-down': !span2Clicked, 'arrow-left': span2Clicked}">
                                        <span>&#10095;</span>
                                    </button>
                                </div>
                            </div>
                            <div v-if="span2Clicked" style="margin-bottom:20px;">
                                <div class="info" style="text-align:left;">
                                    Yes, we are happy to do so, and also offer past client success stories and testimonials.
                                </div>
                            </div>
                        </div>

                        <div class="readmore-border">
                            <div class="readmore" @click="toggleSpan4">
                                <div class="content"> What insurance do you hold, and for how much? </div>
                                <div class="button-container">
                                    <button class="yellow-arrow" :class="{'arrow-down': !span4Clicked, 'arrow-left': span4Clicked}">
                                        <span>&#10095;</span>
                                    </button>
                                </div>
                            </div>
                            <div v-if="span4Clicked" style="margin-bottom:20px;">
                                <div class="info" style="text-align:left;">
                                    We have liability insurance up to $5 million, and our trades each hold their own liability policies.  In addition, we have Workplace Safety and Insurance Board (WSIB) insurance.
                                </div>
                            </div>
                        </div>

                        <div class="readmore-border">
                            <div class="readmore" @click="toggleSpan6">
                                <div class="content"> What warranties do you offer? </div>
                                <div class="button-container">
                                    <button class="yellow-arrow" :class="{'arrow-down': !span6Clicked, 'arrow-left': span6Clicked}">
                                        <span>&#10095;</span>
                                    </button>
                                </div>
                            </div>
                            <div v-if="span6Clicked" style="margin-bottom:20px;">
                                <div class="info" style="text-align:left;">
                                    We guarantee our craftsmanship work for two years, and all manufacturers' warranties are transferred to the homeowner.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="schedule-call"></div>
                </div>

            </div>


            <div class="moving-div bottom-to-top" >
                <div class="row container" style="align-items:center;">
                    <div class="col-12 col-md-6 text-center">
                        <img src="../assets/images/bookcover.png" style="width:80%; max-width:450px; margin:40px 0;" />
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="title">
                            Learn the Common Mistakes
                        </div>
                        <div class="title" style="font-size: 24px;">
                            In the Renovation Industry & How to Avoid Them
                        </div>
                        <ul class="info" style="font-size: 14px; margin: 20px 0 0 16px;">
                            <li>How to deal with the 5 most common mistakes confidently</li>
                            <li>How to stay safe and learn what you need to ask</li>
                            <li>Expand your knowledge with industry tips and tricks</li>
                        </ul>
                        <div class="info" style="margin: 20px 0;">
                            Here Use the same customer information as existing website
                        </div>

                        <div @click="popupDownload" class="download-text info">
                            Download Your Free Ebook
                        </div>
                    </div>
                </div>
            </div>


            

          
        </div>
    
        <popupModal v-show="showModal" @close-modal="showModal = false" @save="save"  />

        <messageModal v-show="showMsgModal" @close-modal="showMsgModal = false" ref="msgModal" />

    </div>
</template>

<script>
    import popupModal from '../components/modals/popupModal.vue';
    import messageModal from '../components/modals/messageModal.vue';
    
    export default {
        name: 'Home',
        props: {
            msg: String
        },
        components: {
            popupModal, messageModal,
            
          
        },
         
        data() {
            return {
                showModal: false,
                showMsgModal: false,
                divs: [],
                span1Clicked: false,
                span2Clicked: false,
                span3Clicked: false,
                span4Clicked: false,
                span5Clicked: false,
                span6Clicked: false,

                review1: false,
                review2: false,
                review3: false,
                review4: false,


            }
        },
        mounted() {

         
            window.addEventListener('load', () => {
                this.divs = document.getElementsByClassName("moving-div");
                window.addEventListener("scroll", this.handleScroll);

                var calendars = document.getElementsByClassName("schedule-call");
                Array.prototype.forEach.call(calendars, function (item) {
                    item.addEventListener('click', function () {
                        document.getElementsByClassName('calendly-badge-content')[0].click();
                    });
                });
                 
            });
             
        },
        beforeDestroy() {
            window.removeEventListener("scroll", this.handleScroll);
        },
     
        methods: {

            showReview1() {
                this.review1 = !this.review1;
            },
            showReview2() {
                this.review2 = !this.review2;
            },
            showReview3() {
                this.review3 = !this.review3;
            },
            showReview4() {
                this.review4 = !this.review4;
            },

            toggleSpan1() {
                this.span1Clicked = !this.span1Clicked;
            },
            toggleSpan2() {
                this.span2Clicked = !this.span2Clicked;
            },
            toggleSpan3() {
                this.span3Clicked = !this.span3Clicked;
            },
            toggleSpan4() {
                this.span4Clicked = !this.span4Clicked;
            },
            toggleSpan5() {
                this.span5Clicked = !this.span5Clicked;
            },
            toggleSpan6() {
                this.span6Clicked = !this.span6Clicked;
            },
            handleScroll() {
                const windowHeight = window.innerHeight;
                //const scrollY = window.scrollY;

                Array.from(this.divs).forEach((div) => {
                    const rect = div.getBoundingClientRect();
                    //console.log("height", rect.top + ", " + rect.bottom, windowHeight + ", " + (rect.bottom - rect.top));
                    if (
                        rect.top <= windowHeight &&
                        rect.bottom <= windowHeight + rect.bottom - rect.top 
                    ) {

                        if (div.classList.contains("b2t") && !div.classList.contains("bottom-to-top")) {
                            //console.log("`````````````````````````````````effect!`````````````````````````");
                            div.classList.add("bottom-to-top");
                        } else if (div.classList.contains("r2l") && !div.classList.contains("right-to-left")) {
                            div.classList.add("right-to-left");
                        } else if (div.classList.contains("t2b") && !div.classList.contains("top-to-bottom")) {
                            div.classList.add("top-to-bottom");
                        } else if (div.classList.contains("l2r") && !div.classList.contains("left-to-right")) {
                            div.classList.add("left-to-right");
                        }

                       
                    }
                });
            },
            laneway() {
                this.$router.push('/laneway');
            },
            additions() {
                this.$router.push('/Additions');
            },
            portfolio() {
                this.$router.push('/OurPortfolio');
            },
            popupDownload() {

                this.showModal = true;
            },
            displayModal(msg) {
                this.messageModal = true;
                this.$refs.msgModal.showMessage(msg, 2000);
            },
            save() {
                this.showModal = false;
                this.displayModal("Thank you, your free e book is on its way");
                document.getElementById("downloadBook").click();
            }
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    @import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css';

    .fullscreen {
         
    }
    .paged {
        min-height: 1000px;
    }
    .black-bg{background-color:black;}

    .review-box {
        font-size: 18px;
        border: 1.5px solid white;
        border-radius: 5px;
        margin: 16px 8px;
        padding: 16px;
        background-color: rgba(0, 0, 0, .1);
        font-family: 'Antic Didone', serif;
    }
    .review-title {
        color:#f6d477  ;
    }
    .review-subtitle {
        color: #e6a502;
        cursor:pointer;
        margin: 10px 0;
    }

    .review-info{
        color:white;
        font-size:16px;
    }

    .readmore-border {
        width: 100%;
        border: 1px solid white;
        border-radius: 6px;
        min-height: 70px;
        margin: 20px 0;
        padding: 0 20px;
    }

    .readmore {
        cursor: pointer;
        color: #e6a502;
        font-family: 'Antic Didone', serif;
        line-height: 70px;
        font-size: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .button-container {
        margin-left: auto;
    }

    .yellow-arrow {
        background-color: #333;
        border-radius: 50%;
        border:none;
        color: white;
        width: 45px;
        height: 45px;
        float: right;
        line-height: 45px;
    }

    .arrow-down {
        transform: rotate(90deg);
    }


    .swiper-slide{text-align:center;}
    .swiper-slide img {
        margin: 10px;
        max-width: calc(100% - 20px);
        height: auto;
    }

    .swiper-pagination-bullet {
        background: white !important;
        
    }
    .checked {
        color: #f6d477;
    }
    .unchecked {
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #f6d477;
    }
    .home-bg {
        background-color: #000;
        background-image: linear-gradient(rgba(0, 0, 0, .75), rgba(0, 0, 0, .85)), url('../assets/images/new/homebg1.png');
        background-position: center;
        background-size: cover;
        padding-top: 50px;
        padding-bottom: 30px;
    }

    .home-bg2 {
        background-color: #000;
        background-image: linear-gradient(rgba(0, 0, 0, .75), rgba(0, 0, 0, .85)), url('../assets/images/new/homebg2.jpg');
        background-position: center;
        background-size: cover;
        padding-top: 50px;
        padding-bottom: 30px;
    }
    .home-bg3 {
        background-color: #000;
        background-image: linear-gradient(rgba(0, 0, 0, .75), rgba(0, 0, 0, .85)), url('../assets/images/new/homebg3.jpg');
        background-position: center;
        background-size: cover;
        padding-top: 50px;
        padding-bottom: 30px;
    }
    .home-title {
        font-size: 40px !important;
        margin-top: 20px !important;
        padding: 0 10px;
    }
    .box {
        min-height: 140px;
        background-color: rgba(200,200,200,.2);
        padding: 16px;
        display:list-item;
        list-style-position:inside;
        font-size:14px;
    }
    .image-rotate {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    .btn-download {
        cursor: pointer;
        background: url('../assets/images/btn-download.png') no-repeat;
        background-position: center;
        background-size: cover;
        width:353px;
        height:41px;
        margin: 40px auto;
    }

    .trans-box{height: 178px;}


    .download-text{
        font-size: 30px !important;
        color:white;
        cursor:pointer;
        margin: 40px 0;
    }
    .download-text:hover{
        color:#e6a502;
    }
    .profile{background-color:black; border: 1.5px solid #e6a502; padding:16px;justify-content:center; display:flex; }

    .reviews{display:flex; align-items:stretch;}

    .text-under-img{border: 1px solid #e6a502; border-radius:5px; border-bottom:0px; font-size: 24px; line-height:30px;
                    min-width:240px; width:fit-content; margin:auto; padding:2px 20px; margin-bottom:48px; text-decoration:none;}
    @media only screen and (max-width: 768px) {
        .reviews {
            display: unset;
            align-items:unset;
            
        }
    }

</style>

