<template>
    <div class="home">
        <div class="container">
            <div class="row  laneway-bg">
                

                <div class="col-12  title pd40" style="font-size:40px;">
                    Laneway suites in Toronto - <br />An affordable convenient <br />living space
                </div>
                <div class="col-12 info pd40" style="padding-top: 0px; line-height:30px;">
                    The City of Toronto allows the construction of Laneway Suites across the city on residential properties that share a property line with a public lane.
                    In other words, if you own a house/property in Toronto and your backyard or garage backs out onto a public laneway, you can build a self-contained residential unit,
                    giving your home additional space and providing options for living and rental opportunities.
                </div>

            </div>



            <div class="col-12 row">

                <div class="col-12 col-md-6 info pd30" style="padding-top: 40px; min-height: auto;">
                    Suites can include full electrical coverage and plumbing, both of which will be connected to that of the main house, which requires extensive work to the Suite itself, the backyard, and the basement of the main house. While there has been a lot of opposition to these suites recently, they're a great way to increase your living space and create a potential opportunity for more income if you choose to rent it out.
                </div>
                <div class="col-12 col-md-6" style="padding-top: 40px; min-height: 300px;">
                    <img class="img-responsive" src="../assets/images/LANDWAY/landway1.png" style="width:100%; margin:0 auto;" />
                </div>
            </div>


            <div class="col-12 row">
                <div class="col-12 col-md-6" style="padding-top: 40px; min-height: auto;">
                    <img class="img-responsive" src="../assets/images/LANDWAY/landway2.png" style="width:100%; margin:0 auto;" />
                </div>
                <div class="col-12 col-md-6 info pd30" style="padding-top: 40px; min-height: auto;">
                    Sound complicated? It certainly can be - but not to us! Outstanding Construction's team of experts has extensive experience with the building of Laneway Suites and can help guide you through the process from start to finish, ensuring that all the appropriate bylaws are followed to the letter of the law.
                </div>

            </div>

            <div class="col-12 row" style="margin-top:40px;">
                <div class="title col-12 col-md-6" style="color: #ffdc7b; font-size: 30px; text-align:left;">
                    some of the advantages of Laneway Suites?
                </div>

                <div class="col-12 col-md-6" style="color:white;">
                    <div class="info list-item">
                        <i class="list-icon"> </i>
                        Cost-effective option for home owners who are looking to build an addition for additional living space and/or storage.
                    </div>
                    <div class="info list-item">
                        <i class="list-icon"></i>
                        A great way to keep your family together - for example, the parents of a young couple could decide to build a Laneway Suite in order to help the couple with their living arrangements or as a starter living space.
                    </div>
                    <div class="info list-item">
                        <i class="list-icon"></i>
                        Valuable renting opportunities - supplement your income by renting out your Laneway Suite and providing your renter with all of the amenities of a condo or apartment.
                    </div>
                    <div class="info list-item">
                        <i class="list-icon"></i>
                        Raise your property value when/if you decide to sell in the future.

                    </div>
                </div>
                
            </div>
            
            <div class="col-12 row">

                <div style="text-align:center;">
                    <div class="title pd40" style=" font-size:30px; text-align:center;">
                        From start to finish, we've got your back!
                    </div>
                    <div class="info list-item">
                        If you're interested in learning more about Laneway Suites, or you'd like us to come visit your property to see if it meets the bylaw requirements, contact us today! We'll walk you through the entire process and if you decide to proceed, we can also help you take care of all permits and inspections that are required for the construction.
                    </div>
                    <div class="title" style=" font-size:30px; text-align:center;">
                        Laneway housing floor plans
                    </div>
                </div>
                <img class="img-responsive" src="../assets/images/LANDWAY/landway3.png" style="width:100%; margin:20px auto;" />


            </div>    

           




        </div>
    </div>
</template>

<script>
    export default {
        name: 'About Us'

    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!-- background: url('../assets/images/lanewaybg.png'); -->
<style scoped>

    .laneway-bg {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;      
        padding-bottom: 30px;
    }

    .laneway-bg-2 {
        background: url('../assets/images/lanewaybg2.png');
        background-repeat: no-repeat;
        background-position: right;
        background-size: cover;
    }


    .pd40 {
        padding: 40px;
    }

    .pd30 {
        padding: 30px;
    }

    hr {
        height: 2px;
        margin-top: 16px;
        margin-bottom: 16px;
        background-color: white;
        width: 100%;
    }

    .info {
        font-size: 16px;
        line-height: 30px;
    }

    .bg-white {
        background-color: #fff;
    }

    .list-item {
        margin-left: 40px;
        margin-right: 20px;
        margin-bottom: 10px;
        color: white;
    }

    .list-icon {
        background-image: url('../assets/images/list_icon.png');
        background-position: center;
        background-repeat: no-repeat;
        width: 30px;
        height: 30px;
        display: inline-block;
        position: relative;
        top: 10px;
    }
</style>

