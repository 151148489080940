<template>
    <div class="home">
        <div class="container">
            <div class="row  whatweoffer-bg">

                <div class="row col-12" style=" align-items: center;">
                    <div class="col-12 col-md-6  moving-div bottom-to-top b2t">
                        <div class="title" style="text-align:left;">Kitchen Remodeling</div>
                        <div class="info" style="margin-top:30px;">Kitchen is a multifunctional space that embodies the heart and soul of the home, reflecting the homeowner's personality, priorities, and lifestyle, while also serving as a vital center for family life, entertainment, and culinary exploration.</div>

                        <div class="readmore-border">
                            <div class="readmore" @click="toggleSpan1">
                                <div class="content">Read More </div>
                                <div class="button-container">
                                    <button class="yellow-arrow" :class="{'arrow-down': !span1Clicked, 'arrow-left': span1Clicked}">
                                        <span>&#10095;</span>

                                    </button>
                                </div>

                            </div>
                            <div class="info" v-if="!span1Clicked" style="margin-bottom:20px;">
                                The kitchen is frequently referred to as the heart of the home, a central hub where family and friends naturally gather. It's a place for sharing meals, engaging in conversations, and making memories, making it an integral part of the family's daily life. For many homeowners, the kitchen is a canvas for personal expression. It reflects their taste, lifestyle, and priorities through design choices like cabinetry, countertops, appliances, and color schemes. Renovating a kitchen allows homeowners to infuse their personality into the space.
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 moving-div bottom-to-top b2t">

                        <swiper :pagination="pagination" :modules="modules">
                            <swiper-slide v-for="(image, imageIndex) in images" :key="imageIndex">
                                <div class="image-container">
                                    <img :src="image.src" :alt="image.alt" />
                                </div>
                            </swiper-slide>
                        </swiper>

                    </div>

                </div>

                <div class="row col-12" style="margin-top: 40px; align-items: center;">

                    <div class="col-12 col-md-6 moving-div bottom-to-top b2t">

                        <swiper :pagination="pagination" :modules="modules">
                            <swiper-slide v-for="(image, imageIndex) in images1" :key="imageIndex">
                                <div class="image-container">
                                    <img :src="image.src" :alt="image.alt" />
                                </div>
                            </swiper-slide>
                        </swiper>

                    </div>

                    <div class="col-12 col-md-6  moving-div bottom-to-top b2t">
                        <div class="title" style="text-align:left;">Bathroom Remodeling</div>
                        <div class="info" style="margin-top:30px;">
                            Bathroom serves as a private retreat - a place where one can relax, rejuvenate, and have a moment of solitude away from the hustle and bustle of daily life.
                            A long bath or a steamy shower can be a therapeutic escape, offering a sense of renewal.
                        </div>

                        <div class="readmore-border">
                            <div class="readmore" @click="toggleSpan2">
                                <div class="content">Read More </div>
                                <div class="button-container">
                                    <button class="yellow-arrow" :class="{'arrow-down': !span2Clicked, 'arrow-left': span2Clicked}">
                                        <span>&#10095;</span>

                                    </button>
                                </div>

                            </div>
                            <div class="info" v-if="!span2Clicked" style="margin-bottom:20px;">
                                The bathroom presents an opportunity for homeowners to express their style and preferences through design, fixtures, and amenities. From luxurious bathtubs
                                and rainfall showerheads to bespoke vanities and ambient lighting, every element can be tailored to create a space that not only looks beautiful but feels
                                comfortable and inviting. Increasingly, bathrooms are being designed with wellness in mind, incorporating features that promote relaxation and self-care.
                                This might include spa-like amenities such as jacuzzi tubs, saunas, or even spaces for yoga and meditation. For homeowners, the bathroom becomes a central
                                space for nurturing physical and mental well-being.
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row col-12" style="margin-top: 40px; align-items: center;">

                    <div class="col-12 col-md-6  moving-div bottom-to-top b2t">
                        <div class="title" style="text-align:left;">Addition</div>
                        <div class="info" style="margin-top:30px;">
                            The most apparent benefit of a home addition is the increase in living space. This expansion can accommodate growing families, provide room for hobbies or
                            work, or simply offer more breathing room, enhancing the overall comfort and functionality of the home.
                        </div>

                        <div class="readmore-border">
                            <div class="readmore" @click="toggleSpan3">
                                <div class="content">Read More </div>
                                <div class="button-container">
                                    <button class="yellow-arrow" :class="{'arrow-down': !span3Clicked, 'arrow-left': span3Clicked}">
                                        <span>&#10095;</span>

                                    </button>
                                </div>

                            </div>
                            <div class="info" v-if="!span3Clicked" style="margin-bottom:20px;">
                                A home addition allows homeowners to tailor their living space to their specific needs and desires. Whether it's adding a whole floor, a dream kitchen, a home office,
                                a master suite, or a sunroom, an addition can be designed to fulfill precise functions that were previously missing or inadequate. Adding to a home often increases
                                its market value. Quality additions that enhance the home's overall appeal and functionality can make the property more attractive to future buyers, representing a
                                solid investment in the long term. For many homeowners, a home addition is a strategic choice to get the space they need without the stress, expense, and emotional
                                toll of moving. It allows them to stay in a beloved home or neighborhood while adapting their living space to meet their changing needs.
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 moving-div bottom-to-top b2t">

                        <div class="image-container">
                            <img :src="require('../assets/images/WhatWeOffer/image1.png')" />
                        </div>

                    </div>


                </div>

                <div class="row col-12" style="margin-top: 40px; align-items: center;">

                    <div class="col-12 col-md-6 moving-div bottom-to-top b2t">

                        <div class="image-container">
                            <img :src="require('../assets/images/WhatWeOffer/image2.png')" />
                        </div>

                    </div>

                    <div class="col-12 col-md-6  moving-div bottom-to-top b2t">
                        <div class="title" style="text-align:left;">Full Renovation</div>
                        <div class="info" style="margin-top:30px;">
                            A full renovation allows homeowners to completely transform their living spaces, adapting the home to better fit their lifestyle, preferences, and needs.
                            This can include reconfiguring floor plans to create an open concept living area, adding new rooms, or updating existing spaces for improved functionality and aesthetic appeal.
                        </div>

                        <div class="readmore-border">
                            <div class="readmore" @click="toggleSpan4">
                                <div class="content">Read More </div>
                                <div class="button-container">
                                    <button class="yellow-arrow" :class="{'arrow-down': !span4Clicked, 'arrow-left': span4Clicked}">
                                        <span>&#10095;</span>

                                    </button>
                                </div>

                            </div>
                            <div class="info" v-if="!span4Clicked" style="margin-bottom:20px;">
                                Full renovations often involve updating essential systems and infrastructure to ensure the home meets current standards of safety, efficiency,
                                and comfort. This can include electrical, plumbing, heating, ventilation, and air conditioning (HVAC) systems, as well as improving insulation and
                                windows for better energy efficiency. A comprehensive renovation provides the opportunity to overhaul the home's aesthetic, updating it to reflect
                                the homeowners' style and contemporary design trends. This can involve everything from choosing new paint colors and flooring materials to selecting
                                fixtures, finishes, and architectural details that harmonize with the desired look and feel. By updating and modernizing a home, a full renovation
                                can significantly increase its market value. An attractively renovated property is more appealing to potential buyers and can command a higher
                                selling price, making the renovation an investment in the homeowner's financial future. Over time, all homes experience wear and tear that can lead
                                to issues ranging from cosmetic flaws to structural damage. A full renovation addresses these issues head-on, repairing or replacing damaged elements
                                and ensuring the home is safe, sound, and aesthetically pleasing. Renovating a home allows for the integration of modern conveniences and technologies,
                                enhancing the overall functionality and comfort of the living spaces. Smart home technology, energy-efficient appliances, and modern amenities can all
                                be incorporated to create a more comfortable, convenient, and sustainable living environment. Completing a full renovation can offer homeowners a deep
                                sense of personal satisfaction and pride of ownership. There's a unique fulfillment that comes from transforming a space to reflect one's vision
                                and hard work, creating a home that's truly personal and cherished. As families grow and needs change, a full renovation can adapt a home to meet
                                these evolving requirements. Whether it's creating more space with an addition, converting rooms to serve new purposes, or making the home more
                                accessible, renovations can ensure the home continues to meet the homeowner's needs over time.
                            </div>
                        </div>
                    </div>


                </div>

                <div class="row col-12" style="margin-top: 40px; align-items: center;">

                    <div class="col-12 col-md-6  moving-div bottom-to-top b2t">
                        <div class="title" style="text-align:left;">Basement Renovation</div>
                        <div class="info" style="margin-top:30px;">
                            Renovating a basement can significantly increase a home's usable living space without the need to construct an addition. This space can be used for various purposes,
                            including bedrooms, family rooms, or home offices, providing room for a growing family or changing needs.
                        </div>

                        <div class="readmore-border">
                            <div class="readmore" @click="toggleSpan5">
                                <div class="content">Read More </div>
                                <div class="button-container">
                                    <button class="yellow-arrow" :class="{'arrow-down': !span5Clicked, 'arrow-left': span5Clicked}">
                                        <span>&#10095;</span>

                                    </button>
                                </div>

                            </div>
                            <div class="info" v-if="!span5Clicked" style="margin-bottom:20px;">
                                A well-executed basement renovation can enhance the overall value of a home. Potential buyers often see a finished basement as a major plus, which can make the property
                                more attractive in the real estate market. By converting a basement into a separate apartment or living space, homeowners can generate additional income through renting.
                                This can be especially appealing as a way to offset mortgage payments or as a means of passive income. Some homeowners renovate their basements to fulfill specific needs
                                or desires, such as creating a home gym, cinema room, workshop, or hobby space. These dedicated spaces allow for activities that might not fit elsewhere in the home. During
                                renovation, homeowners can improve their basement's insulation and ventilation, leading to greater energy efficiency throughout the home. This can result in lower heating
                                and cooling costs and a more comfortable living environment. Renovating allows homeowners to address any existing issues, such as moisture problems, mold, or poor insulation.
                                Taking proactive steps can prevent future damage and improve the home's overall condition and safety. Finally, renovating a basement can be a project of personal satisfaction,
                                allowing homeowners to customize their space according to their tastes and preferences. A renovated basement can enhance the aesthetic appeal and functionality of a home,
                                making it a more enjoyable place to live.
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 moving-div bottom-to-top b2t">

                        <div class="image-container">
                            <img :src="require('../assets/images/WhatWeOffer/image3.png')" />
                        </div>

                    </div>

                </div>



            </div>


        </div>
    </div>
</template>

<script>
    import { Pagination } from 'swiper'
    import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

    export default {
        name: 'What We Offer',
        components: {
            Swiper,
            SwiperSlide
        },
        setup() {
            return {
                pagination: {
                    clickable: true,
                    renderBullet: function (index, className) {
                        return '<span class="' + className + '">' + '</span>';
                    },
                },
                modules: [Pagination],
            };
        },
        data() {
            return {
                span1Clicked: true,
                span2Clicked: true,
                span3Clicked: true,
                span4Clicked: true,
                span5Clicked: true,
                images: [
                    { id: 1, src: require('../assets/images/WhatWeOffer/slide1.png'), alt: 'Image' },
                    { id: 2, src: require('../assets/images/WhatWeOffer/slide1-2.png'), alt: 'Image' },
                    { id: 3, src: require('../assets/images/WhatWeOffer/slide1-3.png'), alt: 'Image' },
                    { id: 4, src: require('../assets/images/WhatWeOffer/slide1-4.png'), alt: 'Image' },
                ],
                images1: [
                    { id: 1, src: require('../assets/images/WhatWeOffer/slide2-1.png'), alt: 'Image' },
                    { id: 2, src: require('../assets/images/WhatWeOffer/slide2-2.png'), alt: 'Image' },
                    { id: 3, src: require('../assets/images/WhatWeOffer/slide2-3.png'), alt: 'Image' },
                    { id: 4, src: require('../assets/images/WhatWeOffer/slide2-4.png'), alt: 'Image' },
                    { id: 1, src: require('../assets/images/WhatWeOffer/slide2-5.png'), alt: 'Image' },
                    { id: 2, src: require('../assets/images/WhatWeOffer/slide2-6.png'), alt: 'Image' },
                    { id: 3, src: require('../assets/images/WhatWeOffer/slide2-7.png'), alt: 'Image' },
                    { id: 4, src: require('../assets/images/WhatWeOffer/slide2-8.png'), alt: 'Image' },
                ],
                swiperOptions: {
                    slidesPerView: 1,
                    navigation: {
                        enabled: true,
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    }
                }

            }

        },
        mounted() {

            window.addEventListener('load', () => {
                this.divs = document.getElementsByClassName("moving-div");
                window.addEventListener("scroll", this.handleScroll);


            });

        },
        beforeDestroy() {
            window.removeEventListener("scroll", this.handleScroll);
        },
        methods: {
            toggleSpan1() {
                this.span1Clicked = !this.span1Clicked;
            },
            toggleSpan2() {
                this.span2Clicked = !this.span2Clicked;
            },
            toggleSpan3() {
                this.span3Clicked = !this.span3Clicked;
            },
            toggleSpan4() {
                this.span4Clicked = !this.span4Clicked;
            },
            toggleSpan5() {
                this.span5Clicked = !this.span5Clicked;
            },

            handleScroll() {
                const windowHeight = window.innerHeight;
                //const scrollY = window.scrollY;

                Array.from(this.divs).forEach((div) => {
                    const rect = div.getBoundingClientRect();
                    //console.log("height", rect.top + ", " + rect.bottom, windowHeight + ", " + (rect.bottom - rect.top));
                    if (
                        rect.top <= windowHeight &&
                        rect.bottom <= windowHeight + rect.bottom - rect.top
                    ) {

                        if (div.classList.contains("b2t") && !div.classList.contains("bottom-to-top")) {
                            //console.log("`````````````````````````````````effect!`````````````````````````");
                            div.classList.add("bottom-to-top");
                        } else if (div.classList.contains("r2l") && !div.classList.contains("right-to-left")) {
                            div.classList.add("right-to-left");
                        } else if (div.classList.contains("t2b") && !div.classList.contains("top-to-bottom")) {
                            div.classList.add("top-to-bottom");
                        } else if (div.classList.contains("l2r") && !div.classList.contains("left-to-right")) {
                            div.classList.add("left-to-right");
                        }


                    }
                });
            },
        }

    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!-- background: linear-gradient( rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.9) ), url('../assets/images/about_bg1.jpg'); -->
<style scoped>

    .swiper-slide {
        display: flex;
    }

    .image-container {
        width: calc(100%-5px);
    }

        .image-container img {
            margin: 10px;
            max-width: calc(100% - 20px);
            height: auto;
        }

    .swiper-button-next, .swiper-button-prev {
        color: white !important;
    }

    .readmore-border {
        width: 100%;
        border: 1px solid white;
        border-radius: 6px;
        min-height: 70px;
        margin: 20px 0;
        padding: 0 20px;
    }

    .readmore {
        cursor: pointer;
        color: #f6d477;
        font-family: 'Antic Didone', serif;
        line-height: 70px;
        font-size: 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .button-container {
        margin-left: auto;
    }

    .yellow-arrow {
        background-color: #f6d477;
        border-radius: 50%;
        color: white;
        width: 45px;
        height: 45px;
        float: right;
        line-height: 45px;
    }

    .arrow-down {
        transform: rotate(90deg);
    }


    hr {
        height: 2px;
        margin-top: 16px;
        margin-bottom: 16px;
        background-color: white;
        width: 100%;
    }
</style>

