<template>
    <div class="home">
        <div class="container">

            <div class="row bg">
                <div class="col-12">

                    <nav class="navbar navbar-expand-lg navbar-light bg-light">
                        <div class="container">
                            <a class="logo" href="/"></a>

                            <div class="menu">
                                <a class="info nav-text" href="/AboutUs">
                                    About us
                                </a>
                                <a class="info nav-text" href="/WhatWeDo">
                                    What we do
                                </a>
                                <a class="info nav-text" href="/OurProcess">
                                    Our Process
                                </a>
                                <a class="info nav-text" href="/OurPortfolio">
                                    Our portfolio
                                </a>
                                <a class="info nav-text" href="/Contact">
                                    Contact
                                </a>
                            </div>
                            <div class="menu2">
                                <button class="navbar-toggler" type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#navbarSupportedContent"
                                        @click="visible=!visible">
                                    <span class="material-icons-outlined gold icon-middle" style="font-size:36px;">menu</span>

                                </button>
                                <div class="navbar-collapse" :class="!visible?'collapse':''" id="navbarSupportedContent">
                                    <ul class="navbar-nav">
                                        <li class="nav-item">
                                            <router-link to="/AboutUs" class="nav-link" @click="visible=!visible">About Us</router-link>
                                        </li>
                                        <li class="nav-item">
                                            <router-link to="/WhatWeDo" class="nav-link" @click="visible=!visible">What We Do</router-link>
                                        </li>
                                        <li class="nav-item">
                                            <router-link to="/OurProcess" class="nav-link" @click="visible=!visible">Our Process</router-link>
                                        </li>
                                        <li class="nav-item">
                                            <router-link to="/OurPortfolio" class="nav-link" @click="visible=!visible">Our Portfolio</router-link>
                                        </li>

                                        <li class="nav-item">
                                            <router-link to="/Contact" class="nav-link" @click="visible=!visible">Contact</router-link>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </nav>

                </div>

                <div class="video-container col-12">
                    <video controls autoplay style="width:100%; ">
                        <source :src="videoSource" type="video/mp4">
                        Your browser does not support the video tag.
                    </video>
                </div>

                <div class="col-12 text-center">
                    <div class="title" style="max-width: 600px; margin: 16px auto;">CLICK HERE TO DOWNLOAD YOUR FREE GUIDE NOW</div>
                </div>
                <div class="col-12">
                    <div class="btn-download" @click="gotoDownload"></div>
                </div>
            </div>

            
           

        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                videoSource: require('@/assets/files/The5MistakesRemodelPromoVid_with_sound.mp4')
            };
        },
        methods: {
            gotoDownload() {
                this.$router.push('/5mistakebook');
            }
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


    .bg {
        background: url('../assets/images/bg1.png') no-repeat;
        background-position: center;
        background-size: cover;
        padding-top: 80px;
        padding-bottom: 30px;
    }

    .video-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px 0px;

    }
    .btn-download {
        cursor: pointer;
        background: url('../assets/images/btn-download.png') no-repeat;
        background-position: center;
        background-size: cover;
        width: 353px;
        height: 41px;
        margin: 20px auto;
    }

</style>

