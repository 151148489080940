<template>
    <div class="home">
        <div class="container">
            <div class="row  bg">
                <div class="col-6" style="padding-left:30px;">
                    <a class="logo" href="/"></a>
                </div>
                <div class="col-6" style="padding-right:30px; padding-top:40px; text-align:right;">
                    <div class="ourportfolio">
                        <span class="fa-solid fa-arrow-left" style="color: #f6d477; margin-right:16px;"></span>
                        <a class="title back-arrow" style="margin-left:0px;" href="/OurPortfolio">Our Portfolio</a>
                    </div>

                </div>

                <div class="row">
                    <div class="col-6">
                        <div class="title" style="text-align:left; margin-top:30px; margin-bottom:30px; font-size:50px;">{{obj.title}}</div>
                        <div class="title price">Cost: {{obj.price}}</div>

                    </div>

                    <div class="col-6 text-center">
                        <img src="../assets/images/EASTYORK/EAST_YORK_IMAGE_4.png" style="width:100%; max-width:400px; margin:auto;" class="img-responsive" />
                    </div>

                </div>



                <div class="col-12 row" style="display:flex; margin:32px 0px; padding:0px 10px !important; ">
                    <div class="col-6 col-md-4 lightBg">
                        <div class="title text" style="">Completion Date:</div>
                        <div class="text2">{{obj.completeDate}}</div>

                    </div>
                    <div class="col-6 col-md-4 lightBg">
                        <div class="title text">Property type :</div>
                        <div class="text2">{{obj.type}}</div>

                    </div>

                    <div class="col-12 col-md-4 lightBg">
                        <div class="title text">Duration:</div>
                        <div class="text2">{{obj.duration}}</div>

                    </div>
                </div>

                <div class="row content">
                    <div class="col-6 text-center">
                        <img src="../assets/images/EASTYORK/EAST_YORK_IMAGE_3.png" style="width:100%; max-width:400px; margin:auto;" class="img-responsive" />
                    </div>
                    <div class="col-6" style="vertical-align:top;">
                        <div class="desc" style="text-align:right;">
                            For this particular job, our team was tasked with the demolition and full renovation of the main floor in a large two-storey house in East York. After the initial planning stages were completed, we got to work, starting off by fully demolishing the main floor and existing walls throughout. The original electrical wiring throughout the floor was very outdated (and frankly unsafe) knob and tube-style, and we fully updated it to a much more modern style.
                        </div>
                    </div>

                </div>

                <div class="row content">

                    <div class="col-6">
                        <div class="desc" style="text-align:left;">
                            Upon building the new-and-improved walls, we also added new spray foam insulation to the exterior walls, soundproofed and fireproofed adjoining walls, installed new drywall, and made the walls much more luxurious and attractive to the eyes in general. Our team installed brand new potlights throughout the main floor, which bathes the renovated space in a lush, warm light.
                        </div>
                    </div>
                    <div class="col-6 text-center">
                        <img src="../assets/images/EASTYORK/EAST_YORK_IMAGE_2.png" style="width:100%; max-width:400px; margin:auto;" class="img-responsive" />
                    </div>
                </div>


                <div class="row content">
                    <div class="col-6 text-center">
                        <img src="../assets/images/EASTYORK/EAST_YORK_IMAGE_1.png" style="width:100%; max-width:400px; margin:auto;" class="img-responsive" />
                    </div>
                    <div class="col-6" style="vertical-align:middle;">
                        <div class="desc" style="text-align:right;">
                            The brand new flooring included 5-inch hardwood planks with a thickness of 3/4 an inch, with an accompanying subfloor, also at 3/4 inch thick. The gorgeous entrance foyer is highlighted by its marble tile foyer, with each tile being 2 feet by 2 feet. The new fireplace was installed along with a custom bookcase that wraps around it, bringing an air of extravagance that is also reinforced by the new open-rise staircase and accompanying frameless glass banisters.
                        </div>
                    </div>

                </div>


             

                <div class="col-12" style="position:relative; margin-top:40px;">

                    <div class="nextpage">
                        <a class="title back-arrow" style="margin-right:0px;" href="/PortfolioBlandford">Blandford Avenue</a>
                        <span class="fa-solid fa-arrow-right" style="color: #f6d477; margin-left:16px; "></span>

                    </div>


                </div>
            </div>



        </div>
    </div>
</template>

<script>
    export default {
        name: 'Portfolio',
        data() {

            return {

                obj: {
                    title: 'East York - Main Floor Renovation',
    
                    completeDate: 'September 2021',
                    type: 'Residential',
                    price: '$160,000',
                    duration: '4 Months',
                    desc: '',
                    next: ''
                }

            }
        },

        mounted() {

        },
        methods: {





        }

    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    @import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css';

    .bg {
        background: linear-gradient( rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.9) ), url('../assets/images/ouporfiliobg.jpg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        padding-top: 80px;
        padding-bottom: 30px;
    }

    .lightBg {
    }

        .lightBg .text {
            font-size: 20px !important;
            padding: 16px 0px 16px 16px;
        }

        .lightBg .text2 {
            font-size: 18px !important;
            color: white;
            padding: 0 0 16px 16px;
        }

    .price {
        color: white;
        font-size: 30px !important;
    }

    .desc {
        color: #ccc;
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 16px;
        margin-top: 16px;
        display: table-cell;
        vertical-align: middle;
    }

    .back-arrow {
        color: #ccc !important;
        font-size: 26px;
        text-decoration: none;
        display: block;
        margin: 12px;
    }

        .back-arrow:hover {
            color: #f6d477 !important;
        }

    .content {
        display: table;
        margin-top: 32px;
    }

        .content div {
            display: table-cell;
          
        }

    .ourportfolio {
        display: flex;
        float: right;
    }

        .ourportfolio span {
            font-size: 20px;
            line-height: 60px;
        }

    .nextpage {
        display: flex;
        float: right;
    }

        .nextpage span {
            font-size: 20px;
            line-height: 60px;
        }

    .bottom-right {
        position: absolute;
        bottom: 16px;
        right: 16px;
        font-size: 30px !important;
        color: #f6d477;
        line-height: 60px;
    }


    .top-left2 {
        position: absolute;
        top: 32px;
        left: 32px;
    }


    .bottom-right2 {
        position: absolute;
        bottom: 16px;
        right: 16px;
        font-size: 30px !important;
        color: #f6d477;
        line-height: 60px;
        display: flex;
        cursor: pointer;
    }

        .bottom-right2 span {
            font-size: 20px;
            line-height: 60px;
        }


    @media only screen and (max-width: 900px) {

        .top-left2 {
            position: absolute;
            top: 8px;
            left: 16px;
            display: flex;
        }

        .bottom-right2 {
            font-size: 22px !important;
            line-height: 50px;
        }

            .bottom-right2 span {
                font-size: 20px;
                line-height: 50px;
            }
    }

    @media only screen and (max-width: 600px) {
        .back-arrow {
            font-size: 20px;
        }

        .ourportfolio span {
            font-size: 16px;
            line-height: 55px;
        }

        .bottom-right {
            font-size: 20px !important;
            line-height: 40px;
        }

        .top-left2 {
            position: absolute;
            top: 8px;
            left: 16px;
            display: flex;
        }

        .bottom-right2 {
            font-size: 14px !important;
            line-height: 40px;
        }

            .bottom-right2 span {
                font-size: 14px;
                line-height: 40px;
            }
    }
</style>

