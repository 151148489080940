<template>
    <div class="home">
        <div class="container">
            <div class="row  aboutus-bg">
 

                <div class="col-12 text-center title pd40 moving-div bottom-to-top b2t">
                    About Us
                </div>
                <div class="col-12 info pd30 moving-div bottom-to-top b2t" style="padding-top: 0px;">
                    Homeowners agree that if they need general contracting services, they call Outstanding Construction Inc. We are one of the region's most responsive
                    and top-rated general contractors, known especially for our high-quality workmanship and professionalism on the job. With fair, market-driven pricing
                    and a communication-centric approach to project-delivery. it's no surprise that our work is the most sought-after in the local area.<br />
                    For general contracting projects big and small, call us now!
                </div>

            </div>



            <div class="row">
                <div class="col-12  title pd40 moving-div bottom-to-top b2t">
                    General contractor with experience
                </div>
                <div class="col-12 info pd30 moving-div bottom-to-top b2t" style="padding-top: 0px;">
                    Our clients love the fact that we bring a wealth of knowledge to the table when it comes to project management, 
                    electrical, plumbing, safety/first aid, etc. Whether it's our in-house construction crews or our trusted subcontractors, 
                    everyone on the team knows exactly what they need to do, when they need to do it. Proactive and knowledgeable where it matters, 
                    our team is always raising the bar on professionalism.
                </div>


            </div>

            <div class="row">
                <div class="col-12  title pd40 moving-div b2t" style="padding-top: 0px;">
                    Quality first contractor
                </div>
                <div class="col-12 info pd30 moving-div b2t" style="padding-top: 0px;">
                    When we start on a project, we know right from the get-go what the result will be. We know that our attention to detail and commitment to our craft is 
                    virtually unparalleled in the local area. We believe that quality, durability, and reliability should come before everything else. We believe that hard 
                    work pays off. We believe that our work should last for years to come. After all, there simply are no substitutes for quality work.
                </div>


            </div>

            <div class="row">
                <div class="col-12  title pd40 moving-div b2t" style="padding-top: 0px;">
                    Accurate pricing estimate
                </div>
                <div class="col-12 info pd30 moving-div b2t" style="padding-top: 0px;">
                    When you call Outstanding Construction Inc, we will sit down with you to draft a quote. Using specialized software, we are able to make more precise quotes faster
                    than ever before. We are proud that our clients can trust that our work will stick to their budget. If anything needs to change on the original plan, we will get your
                    complete approval before going ahead with it.
                </div>


            </div>


        </div>
    </div>
</template>

<script>
    export default {
        name: 'About Us',
        data() {
            return {
                divs: []
            }

        },
        mounted() {

            window.addEventListener('load', () => {
                this.divs = document.getElementsByClassName("moving-div");
                window.addEventListener("scroll", this.handleScroll);


            });

        },
        beforeDestroy() {
            window.removeEventListener("scroll", this.handleScroll);
        },
        methods: {
            handleScroll() {
                const windowHeight = window.innerHeight;
                //const scrollY = window.scrollY;

                Array.from(this.divs).forEach((div) => {
                    const rect = div.getBoundingClientRect();
                    //console.log("height", rect.top + ", " + rect.bottom, windowHeight + ", " + (rect.bottom - rect.top));
                    if (
                        rect.top <= windowHeight &&
                        rect.bottom <= windowHeight + rect.bottom - rect.top
                    ) {

                        if (div.classList.contains("b2t") && !div.classList.contains("bottom-to-top")) {
                            //console.log("`````````````````````````````````effect!`````````````````````````");
                            div.classList.add("bottom-to-top");
                        } else if (div.classList.contains("r2l") && !div.classList.contains("right-to-left")) {
                            div.classList.add("right-to-left");
                        } else if (div.classList.contains("t2b") && !div.classList.contains("top-to-bottom")) {
                            div.classList.add("top-to-bottom");
                        } else if (div.classList.contains("l2r") && !div.classList.contains("left-to-right")) {
                            div.classList.add("left-to-right");
                        }


                    }
                });
            },
        }

    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!-- background: linear-gradient( rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.9) ), url('../assets/images/about_bg1.jpg'); -->
<style scoped>

    .aboutus-bg {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        
        padding-bottom: 30px;
    }


    hr {
        height: 2px;
        margin-top: 16px;
        margin-bottom: 16px;
        background-color: white;
        width: 100%;
    }
</style>

