<template>
    <div class="home">
        <div class="container">
             
            <div class="row " >
              

                <div class="col-12 row moving-div bottom-to-top b2t">
                    <div class="title" style="text-align:center; margin-top:30px; margin-bottom:30px;">Our Porfolio</div>

                </div>

                <div class="info col-12 moving-div bottom-to-top b2t">When it comes to luxurious, opulent and premium-quality home renovation services in the GTA, Outstanding Construction is second-to-none. Over the years, our team has worked on dozens of full-home renovations that truly stand apart from your average renovation - our insistence on using only the highest-quality materials ensures that your gorgeous, awe-inspiring renovation will be the talk of the entire neighbourhood!</div>


                <div class="col-12 text-center title pd40 moving-div bottom-to-top b2t">
                    A selection of outstanding construction
                    projects In Toronto, Ontario.
                </div>

                <div class="col-12 text-center title moving-div bottom-to-top b2t" style="font-size: 26px; text-align:left !important;">
                    Glencrest (East York)
                </div>

                <div class="col-12 info  moving-div bottom-to-top b2t desc" style="padding-top: 0px; max-width:unset;">
                    Converting a bungalow to a two-story home is the type of job that we relish here at Outstanding Construction, and this East York-based project was a recent highlight for our team!
                    <br />
                    For starters, were moved the roof at the new floor level and installed a joist frame, along with new upper walls and, of course, a new, top-of-the-line slate roof,
                    which is the highest-quality material you can use for a roof. On the new second floor, we installed a new owners' suite complete with walk-in closet an ensuite
                    bathroom, in addition to 3 regular bedrooms, each with their own bathroom. We also added a laundry room to the second floor and a living room/seating area, while on
                    the main floor, the kitchen and dining room was completely updated and improved. The basement received a new living area, bathroom, and new oak floors throughout in
                    addition to new spray-foam insulation, drywall, plaster, and a new free-standing steel staircase.
                </div>

                <div class="col-12 pd30">
                    <swiper  :pagination="pagination" :modules="modules" >
                        <swiper-slide v-for="(slide, index) in slides" :key="index">
                            <div v-for="(image, imageIndex) in slide" :key="imageIndex" class="image-container">
                                <img :src="image.src" :alt="image.alt" />
                            </div>
                        </swiper-slide>
                    </swiper>
                </div>


                <div class="col-12 text-center title moving-div bottom-to-top b2t" style="font-size: 26px; text-align:left !important;">
                    Polo Place(Scarborough)
                </div>

                <div class="col-12 info  moving-div bottom-to-top b2t desc" style="padding-top: 0px; max-width:unset;">
                    For this project, our client had two words that they wanted us to keep in mind - <b>Open Concept.</b> So, when we undertook the project and got to work, this was the exact vision that we remembered from start to finish!
                    <br />
                    We began by knocking down the wall between the kitchen and dining room on the main floor, which was the first step in meeting the open concept requirement.
                    New, luxurious hardwood flooring - 4-inch-wide natural oak planks, real top-of-the-line material - was installed throughout the entire main floor, as well 
                    as the second floor. Our team relocated all of the existing plumbing, HVAC and electrical work from the wall, and also upgraded the electrical work from 100 amp 
                    to 200 amp - and we also ran an EV wire to the garage for the client's Tesla. The existing bathroom was completely gutted, with a brand-new shower and stand-up 
                    marble tub installed, a new black 2-piece Water Sense toilet installed and relocated, and new ceramic flooring (with heating capabilities) and drywall installed. 
                    The existing powder room was removed and relocated to a different area of the main floor, and to go along with this, we also built a new office and mudroom. We 
                    installed new dining room build-ins, new top-of-the-line walnut kitchen cabinets and marble counters, a new marble kitchen island, and a much improved and more 
                    opulent main entranceway with smart lighting and a grand archway. The previous fireplace, which was made of old and decrepit brick, was completely removed and 
                    replaced with a more modern fireplace - a gold bio ethanol fireplace - complete with solid cherry mantle. Finally, a brand new staircase from the main to second 
                    floor was installed, as well as a new patio door fromthe dining room to the backyard.
                </div>

                <div class="col-12 pd30">
                    <swiper  :pagination="pagination" :modules="modules">
                        <swiper-slide v-for="(slide, index) in slides2" :key="index">
                            <div v-for="(image, imageIndex) in slide" :key="imageIndex" class="image-container">
                                <img :src="image.src" :alt="image.alt" />
                            </div>
                        </swiper-slide>
                    </swiper>

                </div>
                
                <hr style="height: 1px; color:#fff;"/>

                <div class="col-12 info moving-div bottom-to-top b2t">
                    As you can see from the photos above, we will never skimp on the materials that we use for your home renovation, regardless of what room(s) in the house you want transformed. Looking to completely remodel your bathroom? From copper and bronze fixtures, granite countertops, porcelain tile flooring and more, we can transform any old bathroom into one that's ready to be photographed for a magazine spread. Maybe you're looking to transform your kitchen and make it more modern? From marble countertops, islands and backsplashes to top-of-the-line appliances to premium tile flooring, we'll give your kitchen the full-scale makeover that you're looking for.

                    <br /> <br />
                    And in the event that you're looking to build an addition onto your existing home - or your job is even bigger and you want us to completely gut your house for
                    a whole-home renovation - we've got you covered! We can bring our expertise and skills to any job, no matter how small, and we won't rest until you're more than
                    happy with your extraordinary new home.
                </div>


            </div>



        </div>


        <ImagePopup v-show="showPopup" :image-src="selectedImage" @close-popup="showPopup = false" style="z-index:1000;"></ImagePopup>


    </div>
</template>
 
<script>
    import ImagePopup from '../components/modals/ImagePopup.vue'
    import  { Pagination } from 'swiper'
    import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
 
    // Import the styles (you may need to adjust the path based on your project structure)
    import 'swiper/css';
     
    import 'swiper/css/pagination';

    export default {
        name: 'Portfolio',

        components: {
            ImagePopup, Swiper,
            SwiperSlide
        },
        setup() {
            return {
                pagination: {
                    clickable: true,
                    renderBullet: function (index, className) {
                        return '<span class="' + className + '">' + '</span>';
                    },
                },
                modules: [Pagination],
            };
        },
        data() {

            return {
                Srcs: [
                    require('../assets/images/Profolio/image1.png'),
                    require('../assets/images/Profolio/image5.png'),
                    require('../assets/images/Profolio/image4.png'),
                    require('../assets/images/Profolio/image7.png'),
                    require('../assets/images/Profolio/image2.png'),
                    require('../assets/images/Profolio/image6.png'),
                    require('../assets/images/Profolio/image8.png'),
                    require('../assets/images/Profolio/image9.png'),
                    require('../assets/images/Profolio/union.png'),
                    require('../assets/images/Profolio/image12.png')

                ],
                showPopup: false,
                selectedImage: '',
                divs: [],
                currentIndex: 0,
                images: [
                    { id: 1, src: require('../assets/images/Profolio/ourporfolio-image1.jpg'), alt: 'Image' },
                    { id: 2, src: require('../assets/images/Profolio/ourporfolio-image2.jpg'), alt: 'Image' },
                    { id: 3, src: require('../assets/images/Profolio/ourporfolio-image3.jpg'), alt: 'Image' },
                    { id: 4, src: require('../assets/images/Profolio/ourporfolio-image4.jpg'), alt: 'Image' },
                    { id: 5, src: require('../assets/images/Profolio/ourporfolio-image5.jpg'), alt: 'Image' },
                    { id: 6, src: require('../assets/images/Profolio/ourporfolio-image6.jpg'), alt: 'Image' },
                    { id: 7, src: require('../assets/images/Profolio/ourporfolio-image7.jpg'), alt: 'Image' },
                    { id: 8, src: require('../assets/images/Profolio/ourporfolio-image8.jpg'), alt: 'Image' },
                    { id: 9, src: require('../assets/images/Profolio/ourporfolio-image9.jpg'), alt: 'Image' },
                    { id: 10, src: require('../assets/images/Profolio/ourporfolio-image10.jpg'), alt: 'Image' },

                    { id: 11, src: require('../assets/images/Profolio/ourporfolio-image11.jpg'), alt: 'Image' },
                    { id: 12, src: require('../assets/images/Profolio/ourporfolio-image12.jpg'), alt: 'Image' },
                    { id: 13, src: require('../assets/images/Profolio/ourporfolio-image13.jpg'), alt: 'Image' },
                    { id: 14, src: require('../assets/images/Profolio/ourporfolio-image14.jpg'), alt: 'Image' },
                    { id: 15, src: require('../assets/images/Profolio/ourporfolio-image15.jpg'), alt: 'Image' },
                    { id: 16, src: require('../assets/images/Profolio/ourporfolio-image16.jpg'), alt: 'Image' },
                    { id: 17, src: require('../assets/images/Profolio/ourporfolio-image17.jpg'), alt: 'Image' },
                    { id: 18, src: require('../assets/images/Profolio/ourporfolio-image18.jpg'), alt: 'Image' },
                    { id: 19, src: require('../assets/images/Profolio/ourporfolio-image19.jpg'), alt: 'Image' },
                    { id: 20, src: require('../assets/images/Profolio/ourporfolio-image20.jpg'), alt: 'Image' },
                    
                    { id: 21, src: require('../assets/images/Profolio/ourporfolio-image21.jpg'), alt: 'Image' },
                    { id: 22, src: require('../assets/images/Profolio/ourporfolio-image22.jpg'), alt: 'Image' },
                    { id: 23, src: require('../assets/images/Profolio/ourporfolio-image23.jpg'), alt: 'Image' },
                    { id: 24, src: require('../assets/images/Profolio/ourporfolio-image24.jpg'), alt: 'Image' },
                    { id: 25, src: require('../assets/images/Profolio/ourporfolio-image25.jpg'), alt: 'Image' },
                    { id: 26, src: require('../assets/images/Profolio/ourporfolio-image26.jpg'), alt: 'Image' },
                    { id: 27, src: require('../assets/images/Profolio/ourporfolio-image27.jpg'), alt: 'Image' },
                    { id: 28, src: require('../assets/images/Profolio/ourporfolio-image28.jpg'), alt: 'Image' }
                   
 
                ],

                images2: [
                    { id: 1, src: require('../assets/images/Profolio/ourporfolio-image-2-1.jpg'), alt: 'Image' },
                    { id: 2, src: require('../assets/images/Profolio/ourporfolio-image-2-2.jpg'), alt: 'Image' },
                    { id: 3, src: require('../assets/images/Profolio/ourporfolio-image-2-3.jpg'), alt: 'Image' },
                    { id: 4, src: require('../assets/images/Profolio/ourporfolio-image-2-4.jpg'), alt: 'Image' },
                    { id: 5, src: require('../assets/images/Profolio/ourporfolio-image-2-5.jpg'), alt: 'Image' },
                    { id: 6, src: require('../assets/images/Profolio/ourporfolio-image-2-6.jpg'), alt: 'Image' },
                    { id: 7, src: require('../assets/images/Profolio/ourporfolio-image-2-7.jpg'), alt: 'Image' },
                    { id: 8, src: require('../assets/images/Profolio/ourporfolio-image-2-8.jpg'), alt: 'Image' },
                    { id: 9, src: require('../assets/images/Profolio/ourporfolio-image-2-9.jpg'), alt: 'Image' },
                    { id: 10, src: require('../assets/images/Profolio/ourporfolio-image-2-10.jpg'), alt: 'Image' },

                    { id: 11, src: require('../assets/images/Profolio/ourporfolio-image-2-11.jpg'), alt: 'Image' },
                    { id: 12, src: require('../assets/images/Profolio/ourporfolio-image-2-12.jpg'), alt: 'Image' },
                    { id: 13, src: require('../assets/images/Profolio/ourporfolio-image-2-13.jpg'), alt: 'Image' },
                    { id: 14, src: require('../assets/images/Profolio/ourporfolio-image-2-14.jpg'), alt: 'Image' },
                    { id: 15, src: require('../assets/images/Profolio/ourporfolio-image-2-15.jpg'), alt: 'Image' },
                    { id: 16, src: require('../assets/images/Profolio/ourporfolio-image-2-16.jpg'), alt: 'Image' },
                    { id: 17, src: require('../assets/images/Profolio/ourporfolio-image-2-17.jpg'), alt: 'Image' },
                    { id: 18, src: require('../assets/images/Profolio/ourporfolio-image-2-18.jpg'), alt: 'Image' },
                    { id: 19, src: require('../assets/images/Profolio/ourporfolio-image-2-19.jpg'), alt: 'Image' }
               

                ],

                swiperOptions: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                    navigation: {
                        enabled: true,
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    }
                }
            }
        },
        computed: {
            slides() {
                const result = [];
                for (let i = 0; i < this.images.length; i += 2) {
                    const slide = this.images.slice(i, i + 2);
                    result.push(slide);
                }
                return result;
            },

            slides2() {
                const result = [];
                for (let i = 0; i < this.images2.length; i += 2) {
                    const slide = this.images2.slice(i, i + 2);
                    result.push(slide);
                }
                return result;
            },
        },
        mounted() {
           
            window.addEventListener('load', () => {
                this.divs = document.getElementsByClassName("moving-div");
                window.addEventListener("scroll", this.handleScroll);


            });

        },
        beforeDestroy() {
            window.removeEventListener("scroll", this.handleScroll);
        },
        methods: {
            handleScroll() {
                const windowHeight = window.innerHeight;
                //const scrollY = window.scrollY;

                Array.from(this.divs).forEach((div) => {
                    const rect = div.getBoundingClientRect();
                    //console.log("height", rect.top + ", " + rect.bottom, windowHeight + ", " + (rect.bottom - rect.top));
                    if (
                        rect.top <= windowHeight &&
                        rect.bottom <= windowHeight + rect.bottom - rect.top
                    ) {

                        if (div.classList.contains("b2t") && !div.classList.contains("bottom-to-top")) {
                            //console.log("`````````````````````````````````effect!`````````````````````````");
                            div.classList.add("bottom-to-top");
                        } else if (div.classList.contains("r2l") && !div.classList.contains("right-to-left")) {
                            div.classList.add("right-to-left");
                        } else if (div.classList.contains("t2b") && !div.classList.contains("top-to-bottom")) {
                            div.classList.add("top-to-bottom");
                        } else if (div.classList.contains("l2r") && !div.classList.contains("left-to-right")) {
                            div.classList.add("left-to-right");
                        }


                    }
                });
            },
            popImage: function (val) {
                //console.log("image", val);
                this.selectedImage = this.Srcs[val];
                //console.log("image", this.selectedImage );
                this.showPopup = true;

            }

        }
        
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    @import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css';


    .swiper-slide{display:flex;}
    .image-container{width: calc(50% - 5px);}
        .image-container img {
          
            margin: 10px;
            max-width: calc(100% - 20px);
            height: auto;
        }

    .swiper-button-next, .swiper-button-prev{color: white !important;}
    .bg {
        background: linear-gradient( rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.9) ), url('../assets/images/ouporfiliobg.jpg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        padding-top: 80px;
        padding-bottom: 30px;
    }
    .lightBg {
        background: linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) ), #bdb76b;
    }

    .lightBg .text{font-size: 16px !important; padding: 16px 0px 16px 16px;}
    .lightBg .text2{font-size: 14px !important; color:white; padding:0 0 16px 16px;}

    
    .desc{color:#ccc; font-size:14px; line-height:26px; max-width:800px; margin: 32px auto 0px auto;}
     
    .back-arrow{ color: #ccc !important; font-size:26px; text-decoration:none; display:block; margin:12px;}
    .back-arrow:hover{color: #f6d477 !important;}

     

    .top-left { position: absolute; top: 8px; left: 16px; display:flex; }
    .top-left span{font-size:20px; line-height:60px;}

    .bottom-right{position:absolute; bottom: 16px; right: 16px; font-size: 30px !important; color: #f6d477; line-height:60px;}


    .top-left2 { position: absolute; top: 32px; left: 32px;  }
   

    .bottom-right2{position:absolute; bottom: 16px; right: 16px; font-size: 30px !important; color: #f6d477; line-height:60px; display:flex; cursor:pointer;}
    .bottom-right2 span{font-size:20px; line-height:60px;}


    .borderTest{}

    .image1{position:absolute; width:74%; height:34%; top:0; left:0;}
    .image2{position:absolute; width:23.5%; height:16.5%; top:0; right:0;}
    .image3{position:absolute; width:23.5%; height:16.5%; top:17.5%; right:0;}

    .image4{position:absolute; width:24%; height:16.5%; top:35%; left:0;}
    .image5{position:absolute; width:24%; height:16.5%; top:52.5%; left:0;}
    .image6{position:absolute; width:48%; height:34%; top:35%; left:26%;}
    .image7{position:absolute; width:23.5%; height:16.5%; top:35%; right:0;}
    .image8{position:absolute; width:23.5%; height:16.5%; top:52.5%; right:0;}

    .image9{position:absolute; width:100%; height:29.5%; bottom:0; left:0; }
    .image10{position:absolute; width:49.5%; height:16.5%; top:70.5%; left:0; z-index:100;}

    @media only screen and (max-width: 900px) {
      
         .top-left2 { position: absolute; top: 8px; left: 16px; display:flex; }
        .bottom-right2{font-size: 22px !important; line-height:50px;}
           .bottom-right2 span{font-size:20px; line-height:50px;}
    }
    @media only screen and (max-width: 600px) {

         


        .title{font-size: 28px;}
        .back-arrow{font-size:20px;}
        .top-left span{font-size:16px; line-height:55px;}

        .bottom-right {
            font-size: 20px !important; line-height: 40px;
        }
         
         .top-left2 { position: absolute; top: 8px; left: 16px; display:flex; }
          .bottom-right2{font-size: 14px !important; line-height:40px;}
          .bottom-right2 span{font-size:14px; line-height:40px;}
    }
</style>

