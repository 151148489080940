<template>
    <div class="home">
        <div class="container">
            <div class="row  bg">
           
                <div class="row" style="padding:40px; ">

                    <div class="col-12 col-md-6 hide-mobile " >
                        <div class="gold title moving-div bottom-to-top b2t" style="text-align: left;">
                            <span class="material-icons-outlined gold icon-middle" style="font-size: 36px; ">location_on</span> <span class="text-middle">Address </span>
                        </div>
                        <div class="info moving-div bottom-to-top b2t" style="margin: 8px;">East York ON M4C 1Z5</div>

                        <div class="gold title moving-div bottom-to-top b2t" style="margin-top: 24px; text-align: left;">
                            <span class="material-icons-outlined gold icon-middle" style="font-size:36px;">stay_current_portrait</span> <span class="text-middle">Phone number </span>
                        </div>
                        <div class="info moving-div bottom-to-top b2t" style="margin: 8px;">Phone: (416) 676-0325</div>


                        <div class="gold title moving-div bottom-to-top b2t" style="margin-top: 24px; text-align: left;">
                            <span class="material-icons-outlined gold icon-middle" style="font-size:36px;">email</span> <span class="text-middle">Email </span>
                        </div>
                        <div class="info moving-div bottom-to-top b2t" style="margin: 8px;">info@outstandingconstruction.ca</div>

                        <div class="gold title moving-div bottom-to-top b2t" style="margin-top: 24px; text-align: left;">
                            <span class="material-icons-outlined gold icon-middle" style="font-size:36px;">access_time</span> <span class="text-middle">Hours of operation </span>
                        </div>
                        <div class="info moving-div bottom-to-top b2t" style="margin: 8px;">Mon - Fri: 8:00AM - 5:00PM<br />Sat & Sun: Closed</div>

                        <div class="gold title moving-div bottom-to-top b2t" style="margin-top: 24px; text-align: left;">
                            <span class="text-middle">Socials</span>
                        </div>
                        <div style="color:white;" >
                            <a external href="https://www.instagram.com/outstandingconstruction_inc/" target="_blank" class="instagram-icon moving-div bottom-to-top b2t"></a>  
                        </div>

                    </div>
                    <div class="col-12 col-md-12 trans-bg">
                        <div class="text-center title moving-div bottom-to-top b2t">
                            Contact Us
                        </div>
                        <div>
                            <div class="title moving-div bottom-to-top b2t" style="font-size: 20px; padding: 20px 20px 10px 20px; text-align:left;">First Name</div>
                            <input class="input moving-div bottom-to-top b2t" name="firstname" v-model="info.firstname" />
                        </div>
                        <div>
                            <div class="title moving-div bottom-to-top b2t" style="font-size: 20px; padding: 20px 20px 10px 20px; text-align: left;">Last Name</div>
                            <input class="input moving-div bottom-to-top b2t" name="lastname" v-model="info.lastname" />
                        </div>

                        <div>
                            <div class="title moving-div bottom-to-top b2t" style="font-size: 20px; padding: 20px 20px 10px 20px; text-align: left;">Email</div>
                            <input class="input moving-div bottom-to-top b2t" name="email" v-model="info.email" />
                        </div>

                        <div>
                            <div class="title moving-div bottom-to-top b2t" style="font-size: 20px; padding: 20px 20px 10px 20px; text-align: left;">Phone Number</div>
                            <input class="input moving-div bottom-to-top b2t" name="phone" v-model="info.phone" />
                        </div>

                        <div>
                            <div class="title moving-div b2t" style="font-size: 20px; padding: 20px 20px 10px 20px; text-align: left;">Inquiry</div>
                            <textarea class="textarea moving-div b2t" name="inquiry" v-model="info.Message"  />
                        </div>

                        <div class="col-12 row">
                            <div class="col-12 col-md-12 moving-div b2t">
                                <label for="email" style="display:block; text-indent:-15px; padding-left:35px; color:#f6d477; margin-top:16px;">  <input type="checkbox" class="checkbox" id="email" v-model="info.contactByEmail" style="margin-right:16px;" />contact me by Email</label>
                                <label for="phone" style="display: block; text-indent: -15px; padding-left: 35px; color: #f6d477; margin-top: 16px; ">  <input type="checkbox" class="checkbox" id="phone" v-model="info.contactByPhone" style="margin-right:16px;" />contact me by Phone number</label>
                            </div>
                            <div class="col-12 col-md-12 moving-div b2t" style="margin-top:16px;">
                                <div style="width:304px; margin:auto;" class="g-recaptcha" data-sitekey="6LcJrWkkAAAAANwkAy9q8I-bzJEH1-NBq58SHxSM"></div>
                            </div>
                        </div>



                        <div class="btn-white btn-white-bg moving-div t2b" style="margin-top: 32px; margin-bottom: 32px; height: 40px;" @click="beforeSubmit">
                            <span class="text-middle"> Get in touch </span>
                        </div>

                    </div>
                </div>
            </div>
            <messageModal v-show="showModal" @close-modal="showModal = false"  ref="msgModal" />

        </div>
    </div>
</template>

<script>
    import axios from "axios";
    import messageModal from '../components/modals/messageModal.vue';
    export default {
        name: 'Contact',
        components: { messageModal },
        data() {
            return {
                info: {
                    firstname: "",
                    lastname: "",
                    email: "",
                    phone: "",
                    contactByEmail: true,
                    contactByPhone: true,
                    FromContact: true,
                    Message: ""
                },
                showModal: false,
                divs: []
            }
        },
        mounted() {
            let recaptchaScript = document.createElement('script');
            recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js');
            document.head.appendChild(recaptchaScript);
            window.addEventListener('load', () => {
                this.divs = document.getElementsByClassName("moving-div");
                window.addEventListener("scroll", this.handleScroll);


            });
        },
        beforeDestroy() {
            window.removeEventListener("scroll", this.handleScroll);
        },
        methods: {
            handleScroll() {
                const windowHeight = window.innerHeight;
                //const scrollY = window.scrollY;

                Array.from(this.divs).forEach((div) => {
                    const rect = div.getBoundingClientRect();
                    //console.log("height", rect.top + ", " + rect.bottom, windowHeight + ", " + (rect.bottom - rect.top));
                    if (
                        rect.top <= windowHeight &&
                        rect.bottom <= windowHeight + rect.bottom - rect.top
                    ) {

                        if (div.classList.contains("b2t") && !div.classList.contains("bottom-to-top")) {
                            //console.log("`````````````````````````````````effect!`````````````````````````");
                            div.classList.add("bottom-to-top");
                        } else if (div.classList.contains("r2l") && !div.classList.contains("right-to-left")) {
                            div.classList.add("right-to-left");
                        } else if (div.classList.contains("t2b") && !div.classList.contains("top-to-bottom")) {
                            div.classList.add("top-to-bottom");
                        } else if (div.classList.contains("l2r") && !div.classList.contains("left-to-right")) {
                            div.classList.add("left-to-right");
                        }


                    }
                });
            },
            beforeSubmit() {
                 
                let recaptchaValue = window.grecaptcha.getResponse();
                if (recaptchaValue) {
                    // submit form or do other actions
                    this.submit();
                } else {
                    this.displayModal("Please verify the reCAPTCHA before proceeding.");
                }
            },

            displayModal(msg) {
                this.showModal = true;
                this.$refs.msgModal.showMessage(msg, 2000);
                 
            },

            submit() {
                let _this = this;
                var url = '/api';
                axios.post(url, _this.info).then(function (response) {

                    if (response.data.Code == 0) {
                        _this.info = {
                            firstname: "",
                            lastname: "",
                            email: "",
                            phone: "",
                            contactByEmail: true,
                            contactByPhone: true,
                            FromContact: true,
                            Message: ""
                        }
                         
                        _this.displayModal("Thank you for reaching out to us. We will be in touch with you shortly.");

                    } else {

                        _this.displayModalalert(response.data.Msg);
                    }

                })
                    .catch(function (error) {
                        
                        _this.displayModal(error);

                    });

            }

        }
        
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    @media only screen and (max-width: 767px) {
        .hide-mobile{display:none;}
    }
    .hide-mobile {
        display: none;
    }
    .bg {
        background: linear-gradient( rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.9) ), url('../assets/images/contactusbg.png');
        background-position: center;
        background-size: cover;
       
        padding-bottom: 30px;
    }

    .input {
        border: none;
        border: 1px solid #f6d477;
        border-radius:4px;
        background: transparent;
        width: 95%;
        outline-width: 0;
        color: white;
        margin-left: 2%;
    }
    .textarea {
        border-radius: 4px;
        border: 1px solid #f6d477;
        background: transparent;
        width: 95%;
        outline-width: 0;
        color: white;
        margin-left: 2%;
        height: 100px;
    }
 
    .input :focus{border: none; border-bottom-color: #f6d477; }


</style>

