<template>
    <div class="home">
        <div class="container">
            <div class="row  additions-bg">
            

                <div class="col-12  title pd40" style="text-align:left;">
                    Additions : a great way to<br /> improve and revamp your home
                </div>
                <div class="col-12 info pd30" style="padding-top: 0px;">
                    When it comes to building opulent, luxurious home additions in the GTA, Outstanding Construction can help your bring your dream home vision to life.
                    We'll work closely with you from start to finish, making sure that you have 100% visibility of the project at all times - from the planning stages,
                    to securing the necessary permits (if any), to the construction work itself, our team will ensure that you're kept up-to-date on all of our progress!
                </div>

            </div>

            <div class="row">
                <div class="col-12  title pd40" style="text-align: left;">
                    Why build an addition?
                </div>
                <div class="col-12 info pd30" style="padding-top: 0px;">
                    There are many reasons why homeowners may elect to build an addition onto their home, including:
                </div>


            </div>

            <div class="row">
                <div class="col-12" style="position:relative; margin-top:32px;">
                     
                    <div class="box-left">
                        <div class="title" style="display: list-item; font-size: 30px; text-align: left;">
                            Provide more space to you and your family

                        </div>
                        <div class="info">
                            In today's expensive housing market, building an addition or two on your existing home can provide more space, give your home a more updated or 'modern' feel, and go a long way towards increasing your property value in the event that you decide to sell in the future. Plus, it's much less stressful than house-searching and moving.
                        </div>
                    </div>

                    <img class="right" src="../assets/images/Additions/additional1.jpg" />
                </div>

                <div class="col-12" style="position: relative; margin: 32px 0;">
                     

                    <div class="box-right">
                        <div class="title" style="display: list-item; font-size: 30px; text-align:left;">
                            Give your home a more updated or 'modern' feel

                        </div>
                        <div class="info">
                            Additions such as garden suites, sunrooms, and enclosed patios can spruce up your home by providing more natural light and space to entertain.
                        </div>
                    </div>

                    <img class="left" src="../assets/images/Additions/additional2.jpg" />
                </div>

                <div class="col-12 row">
                    <div class="col-12 col-md-6 box">
                        <div class="title" style="font-size: 30px;">
                            Increase your property value for a potential future sale

                        </div>
                        <div class="info" style="text-align:center;" >
                            Feeling the blues due to 'empty nest syndrome' after your children have moved out of the house? Building an addition can give you and your spouse additional room to do activities together, and also provide additional space when your kids and their families come to visit!
                        </div>
                        <img class="top-circle" src="../assets/images/Additions/additional3.jpg" />
                    </div>
                    <div class="col-12 col-md-6 box">
                        <div class="title" style="font-size: 30px;">
                            Less stressful than house-hunting and moving
                        </div>
                         
                        <img class="top-circle" src="../assets/images/Additions/additional4.jpg" />
                    </div>
                </div>

               

                <div class="col-12" style="position: relative; margin: 32px 0;">
                     

                    <div class="box-left">
                        <div class="title" style="display:list-item; font-size:30px; text-align:left;">

                            Provide more natural light and space to entertain with sunrooms or enclosed patios
                        </div>
                        <div class="info">

                        </div>
                    </div>

                    <img class="right" src="../assets/images/Additions/additional5.jpg" />
                </div>

                <div class="col-12" style="position: relative; margin: 32px 0;">

                     
                    <div class="box-right">
                        <div class="title" style="display: list-item; font-size: 30px; text-align:left;">
                            Help achieve your dream home vision!

                        </div>
                        <div class="info">
                            Chances are that you have a 'vision' for how your home should look, even if you've been living there for several years. Building an addition can help you achieve this vision, ultimately making you and your family much happier in your current home.
                        </div>
                    </div>

                    <img class="left" src="../assets/images/Additions/additional6.jpg" />
                </div>
            </div>




        </div>
    </div>
</template>

<script>
    export default {
        name: 'About Us'

    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!--   background: url('../assets/images/additions_bg.png'); -->

<style scoped>

    .additions-bg {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
       
        padding-bottom: 30px;
    }
    .box{background-color: #222; border: 10px solid black; padding:20px; }
    .top-circle{width: 100%; height:auto; border-radius: 50% 50% 0 0 ; margin-top:32px;}
    .title {
        line-height: 40px;
    }
    .info {
        line-height: 30px;
        margin-top:16px;
    }

    .left {
        max-width: 700px;
        width: 60%;
        float: left;
    }

    .right {
        max-width: 700px;
        width: 60%;
        float: right;
    }

    .box-left {
        background-color: black;
        position: absolute;
        max-width: 420px;
        padding: 30px;
        top: 30px;
    }

    .box-right {
        background-color: black;
        position: absolute;
        max-width: 420px;
        padding: 30px;
        top: 30px;
        right: 0px;
    }

    .gold-dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #f6d477;
    }

    hr {
        height: 2px;
        margin-top: 16px;
        margin-bottom: 16px;
        background-color: white;
        width: 100%;
    }

    @media only screen and (max-width: 768px) {
        .left {
            width: 90%;
            margin-left: 5%;
            float: unset;
        }

        .right {
            width: 90%;
            margin-left: 5%;
            float: unset;
        }

        .box-left {
            bottom: unset;
            position: unset;
            max-width: 100%;
        }

        .box-right {
            bottom: unset;
            position: unset;
            max-width: 100%;
        }
    }
</style>

