<template>
    <div class="footerComp trans-bg">
        <div class="footer-bg" style="margin: 0 auto; ">
            <div class="row container "  style="align-items: center;">
                <div class="col-12 col-md-4" style="text-align:left;">
                    <a class="logo" href="/" style="text-align:left; width:250px; height: 131px; max-width:100%;"></a>
                    <div style="color:white; text-align:left; margin-top:20px;">
                        <a external href="https://www.instagram.com/outstandingconstruction_inc/" target="_blank" class="instagram-icon"></a>
                    </div>
                </div>

                <div class="col-12 col-md-4" style="text-align:left;">
                    <div class="title pd10 " style="text-align:left;">
                        More Info
                    </div>
                    <div class="info pd10">
                        <a class="info nav-text" style="text-align: left; max-width: unset;" href="/AboutUs">
                            About Us
                        </a>
                        <a class="info nav-text" style="text-align: left; max-width: unset;" href="/WhatWeOffer">
                            Bathroom Renovations
                        </a>
                        <a class="info nav-text" style="text-align: left; max-width: unset;" href="/WhatWeOffer">
                            Basement Renovations
                        </a>
                        <a class="info nav-text" style="text-align: left; max-width: unset;" href="/WhatWeOffer">
                            Kitchen Renovations
                        </a>
                        <a class="info nav-text" style="text-align: left; max-width: unset;" href="/Additions">
                            Additions
                        </a>
                        <a class="info nav-text" style="text-align: left; max-width: unset;" href="/Laneway">
                            Laneway Housing
                        </a>
                    </div>
                </div>

                <div class="col-12 col-md-4">
                    <div class="title pd10 " style="text-align:left;">
                        Contact<br />
                        Information - 
                    </div>
                    <div class="info pd10">
                        East York ON M4C 1Z5                        
                    </div>
                    <div class="info " style="padding-left:10px;">
                        Phone: (416) 676-0325
                    </div>
                    <div class="info pd10">
                        <a href="mailto: info@outstandingconstruction.ca" class="info" style="text-decoration: none; ">  Email:  info@outstandingconstruction.ca</a>
                    </div>
                </div>

            </div>
        </div>
     
    </div>
</template>

<script>
    export default {
        name: 'footerComp',
        methods: {
            gotoContact() {
                this.$router.push('/contact');
            }
        }

    };

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!--  background: url('../assets/images/footer.png') no-repeat; -->
<style scoped>
    .footer-bg {
        background-position: center;
        background-size: cover;
        padding-top: 50px;
    }

    .height110 {
        min-height: 110px;
    }

    .footerComp {
        display: flex;
        height: auto;
        width: 100%;
        text-align:center;
    }

    .info{font-size: 16px; color:#939393; cursor:pointer;}
    .info:hover{color:white;}

    .flex{
        display:flex;
    }
    @media only screen and (max-width: 750px) {


        .flex {
            display: inline;
        }
    }

</style>

