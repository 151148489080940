<template>
    <div class="home">
        <div class="container">
            <div class="row  bg">
 

                <div class="col-12 row" style="padding-left:30px;">

                    <div class="col-md-6 col-12 title pd40" style="margin: 30px 0px;">
                        A Selection of<br />
                        Outstanding construction<br />
                        Projects in Toronto, Ontario.
                    </div>
                    <div class="col-md-6 col-12" style="border-left: 3px solid #f6d477;">
                        <a class="nav-arrow title" href="/PortfolioBlandford"> Blandford Avenue <span class="fa-solid fa-arrow-right" style="color: #f6d477; margin-left:4px; "></span> </a>
                        <a class="nav-arrow title" href="/PortfolioEastYork"> East York <span class="fa-solid fa-arrow-right" style="color: #f6d477; margin-left:4px; "></span> </a>
                        <a class="nav-arrow title" href="/PortfolioDufferin"> Dufferin & Eglinton <span class="fa-solid fa-arrow-right" style="color: #f6d477; margin-left:4px; "></span> </a>
                        <a class="nav-arrow title" href="/PortfolioBloor"> Bloor & Kipling<span class="fa-solid fa-arrow-right" style="color: #f6d477; margin-left:4px; "></span> </a>
                        
                    </div>
                </div>
                 

            </div>
          
        
 
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Our Portfolio'
        
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    @import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css'; 

    .bg {
        background: linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.8) ), url('../assets/images/ouporfiliobg.jpg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    
        padding-bottom: 30px;
    }
     
    .nav-arrow{ color: #ccc !important; font-size:26px; text-decoration:none; display:block; margin:12px;}
    .nav-arrow:hover{color: #f6d477 !important;}
</style>

