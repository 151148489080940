<template>
    <div class="home">
        <div class="container">
            <div class="row  aboutus-bg"  >
           

                <div class="col-12 text-center title pd40 moving-div bottom-to-top b2t">
                    Our Process
                </div>
                <div class="col-12 info pd30 moving-div bottom-to-top b2t" style="padding-top: 0px;">
                    If you decide to work with Outstanding Construction for your next luxury home renovation project, we'll guide you through the following steps before we get to work:
                </div>

            </div>

            

            <div class="row">
                <div class="col-12" style="position:relative;">
                    <div class="horizontalLine needToHide"></div>
                    <div class="right needToHide">
                        <div style="display:table-cell;" class="moving-div left-to-right l2r">
                            <img src="../assets/ourprocess_image1.png" class="image" />
                        </div>

                        <div class="title number moving-div right-to-left r2l">01</div>


                    </div>
                    <div class="right-text">
                        <div class="title subtitle moving-div top-to-bottom t2b">
                            Schedule a Phone Call to<br /> Understand Your Needs
                        </div>
                        <div class="title desc moving-div top-to-bottom t2b">
                            In order to get a better understanding of what you're looking for in a home renovation, we'll have a brief phone call with you where you'll have the chance to describe your vision and what you ultimately hope to achieve with your renovation. Tell us your ideas, concerns, ask us questions, and above all, get peace-of-mind after speaking with one of our experts.
                        </div>
                    </div>


                    <div class="left needToHide">
                        <div class="title number moving-div l2r">02</div>

                        <div style="display:table-cell;" class="moving-div r2l">
                            <img src="../assets/ourprocess_image2.png" class="image" />
                        </div>

                    </div>
                    <div class="left-text">
                        <div class="title subtitle  moving-div t2b">
                            In-Person Site Meeting
                        </div>
                        <div class="title desc moving-div t2b">
                            We'll pick a day and time that's most convenient for you and come to your home for an in-person walkthrough. This enables our team to get a better understanding of your vision for your home renovation, and also allow us to see if it's feasible to move forward with the project.
                        </div>

                        
                    </div>

                    <div class="right needToHide">
                        <div style="display:table-cell;" class="moving-div l2r">
                            <img src="../assets/ourprocess_image1.png" class="image" />
                        </div>

                        <div class="title number moving-div r2l">03</div>


                    </div>
                    <div class="right-text">
                        <div class="title subtitle moving-div t2b">
                            Site Meeting with<br /> Architect/Designer
                        </div>
                        <div class="title desc moving-div t2b">
                            An additional on-site meeting will take place with an architect and/or designer, which will definitively determine the feasibility of the project and allow the architect/designer to obtain measurements/figures needed for blueprints and other technical drawings.
                        </div>
                         
                    </div>


                    <div class="left needToHide">
                        <div class="title number moving-div l2r">04</div>

                        <div style="display:table-cell;" class="moving-div r2l">
                            <img src="../assets/ourprocess_image2.png" class="image" />
                        </div>

                    </div>
                    <div class="left-text">
                        <div class="title subtitle moving-div t2b">
                            Detailed Quote Provided
                        </div>
                        <div class="title desc moving-div t2b">
                            Once we've visited your home and the necessary measurements/drawings have been made, we'll put together a detailed quote that outlines the step-by-step process and requirements for your renovation, including blueprints, an estimated timeline/schedule for the entire job from start to finish, information on necessary permits, scheduling sub-trades, etc.
                        </div>
                         
                    </div>


                    <div class="right needToHide">
                        <div style="display:table-cell;" class="moving-div l2r">
                            <img src="../assets/ourprocess_image1.png" class="image" />
                        </div>

                        <div class="title number moving-div r2l">05</div>


                    </div>
                    <div class="right-text">
                        <div class="title subtitle moving-div t2b">
                            Commencement of Work
                        </div>
                        <div class="title desc moving-div t2b">
                            When the above steps have been completed and all parties have agreed upon the scope of the work, we'll get started on your project and provide detailed feedback on our progress as we work. Congratulations - you'll soon be enjoying a high-quality, luxurious home renovation!
                        </div>
                    </div>

                </div>


            </div>
 


        </div>
    </div>
</template>

<script>
    export default {
        name: 'Our Process',
        data() {
            return {
                divs: []
            }

        },
        mounted() {

            window.addEventListener('load', () => {
                this.divs = document.getElementsByClassName("moving-div");
                window.addEventListener("scroll", this.handleScroll);


            });

        },
        beforeDestroy() {
            window.removeEventListener("scroll", this.handleScroll);
        },
        methods: {
            handleScroll() {
                const windowHeight = window.innerHeight;
                //const scrollY = window.scrollY;

                Array.from(this.divs).forEach((div) => {
                    const rect = div.getBoundingClientRect();
                    //console.log("height", rect.top + ", " + rect.bottom, windowHeight + ", " + (rect.bottom - rect.top));
                    if (
                        rect.top <= windowHeight &&
                        rect.bottom <= windowHeight + rect.bottom - rect.top
                    ) {

                        if (div.classList.contains("b2t") && !div.classList.contains("bottom-to-top")) {
                            //console.log("`````````````````````````````````effect!`````````````````````````");
                            div.classList.add("bottom-to-top");
                        } else if (div.classList.contains("r2l") && !div.classList.contains("right-to-left")) {
                            div.classList.add("right-to-left");
                        } else if (div.classList.contains("t2b") && !div.classList.contains("top-to-bottom")) {
                            div.classList.add("top-to-bottom");
                        } else if (div.classList.contains("l2r") && !div.classList.contains("left-to-right")) {
                            div.classList.add("left-to-right");
                        }


                    }
                });
            },
        }
        
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

   .horizontalLine{
       background-color: white;
       height:100%;
       width: 2px;
       position:absolute;
       margin-left:50%;
   }

   

   .right{margin-left: calc(50% - 11px); display:table; margin-top:32px;}
   .left{margin-right: calc(50% - 37px); float:right; margin-top:32px;}

    .left-text{display:table; width:50%;}
    .right-text{margin-left: 50%; display:table; width:50%;}

    
   .image{width:200px; height:auto;}
   .right .number {height:46px; line-height: 46px; padding-left:32px; display:table-cell;  vertical-align:middle;  }
   .left .number {height:46px; line-height: 46px; padding-right:32px; display:table-cell;  vertical-align:middle;  }
   .subtitle{background-color:#E5A901; margin:32px; width:auto; text-align:center; font-size:20px; color:black; font-weight:bold; padding:4px; line-height: 32px;}
   .right-text .desc{color:white; text-align:right; font-size:20px; padding: 0 32px; font-family: Open Sans, sans-serif; line-height: 30px; font-weight:300; text-align:justify;}
   .left-text .desc{color:white; text-align:left; font-size:20px; padding: 0 32px; font-family: Open Sans, sans-serif;line-height: 30px; font-weight:300; text-align:justify;}

    .left-text .warning{text-align:left; font-size:14px; padding: 0 32px; margin-top:32px; color: #E5A901;}
    .right-text .warning{text-align:right; font-size:14px; padding: 0 32px; margin-top:32px; color: #E5A901;}
    .needToHide{display:block;}
    @media only screen and (max-width: 768px) {

        .needToHide{display:none !important; }
        .right {
            margin-left: calc(50% - 5px);
            display: table;
            margin-top: 32px;
        }

        .left {
            margin-right: calc(50% - 31px);
            float: right;
            margin-top: 32px;
        }
         .image{width:150px; height:auto;}

        .left-text {
            display: table;
            width: 100%;
        }

        .right-text {
            margin-left: 0;
            display: table;
            width: 100%;
        }

        .right .number{font-size: 24px; height:35px; line-height:35px; padding-left:16px;}
        .left .number{font-size: 24px; height:35px; line-height:35px; padding-right:16px;}

         .subtitle{background-color:#E5A901; margin:24px; width:auto; text-align:center;   color:black; font-weight:bold; padding:4px;}
        .right-text .desc{color:white; text-align:center;   padding: 0 24px; }
        .left-text .desc{color:white; text-align:center;   padding: 0 24px; }

        .warning{text-align:center !important;}
    }

</style>

