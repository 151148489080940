<template>
    <transition name="modal-fade">


        <div class="modal-overlay" @click="$emit('close-popup')">
            <div class="modal-content" @click="$emit('close-popup')">

                <div class="row">


                    <div class="popup">
                        <img :src="imageSrc">

                    </div>

                </div>

            </div>

        </div>

      
    </transition>
</template>

<script>
    export default {
        props: {
            imageSrc: {
                type: String,
                required: true
            }
        },
        data() {
           
        }
    }
</script>

<style>
    .modal-fade-enter,
    .modal-fade-leave-to {
        opacity: 0;
    }

    .modal-fade-enter-active,
    .modal-fade-leave-active {
        transition: opacity 1s ease;
    }

    .modal-overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .modal-content {
        background-color: rgba(0, 0, 0, 0.5);
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 0 10px black;
        max-width: 400px;
        border: 1px solid #f6d477;
    }

    .popup {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
    }

        .popup img {
            max-width: 90%;
            max-height: 90%;
            object-fit: contain;
            margin: auto;
            display: block;
            z-index:10000;
        }
</style>
