import { createRouter, createWebHistory } from 'vue-router';

import Home from '../pages/Home.vue';
import AboutUs from '../pages/AboutUs.vue';
import Contact from '../pages/Contact.vue';
import OurPortfolio from '../pages/OurPortfolio.vue';
import WhatWeDo from '../pages/WhatWeDo.vue';
import Portfolio from '../pages/Portfolio.vue';
import PortfolioBlandford from '../pages/PortfolioBlandford.vue';
import PortfolioDufferin from '../pages/PortfolioDufferin.vue';
import PortfolioEastYork from '../pages/PortfolioEastYork.vue';
import PortfolioBloor from '../pages/PortfolioBloor.vue';

import The5mistakevideo from '../pages/5mistakevideo.vue';
import The5mistakebook from '../pages/5mistakebook.vue';

import Laneway from '../pages/Laneway.vue';
import Additions from '../pages/Additions.vue';
import OurProcess from '../pages/OurProcess.vue';

import PortfolioNew from '../pages/PortfolioNew.vue';

import PrivacyPolicy from '../pages/PrivacyPolicy.vue';
import WhatWeOffer from '../pages/WhatWeOffer.vue';

const routes = [
    { path: '/', component: Home },
    { path: '/AboutUs', component: AboutUs },
    { path: '/Contact', component: Contact },
    { path: '/5mistakevideo', component: The5mistakevideo },
    { path: '/5mistakebook', component: The5mistakebook },
    { path: '/OurPortfolioOld', component: OurPortfolio },

    { path: '/OurPortfolio', component: PortfolioNew },

    { path: '/WhatWeDo', component: WhatWeDo },
    { path: '/Laneway', component: Laneway },
    { path: '/Additions', component: Additions },
    { path: '/OurProcess', component: OurProcess },
    { path: '/PortfolioBlandford', component: PortfolioBlandford },
    { path: '/PortfolioDufferin', component: PortfolioDufferin },
    { path: '/PortfolioEastYork', component: PortfolioEastYork },
    { path: '/PortfolioBloor', component: PortfolioBloor },

    { path: '/Portfolio/:index', component: Portfolio },
    { path: '/PrivacyPolicy', component: PrivacyPolicy },
    { path: '/WhatWeOffer', component: WhatWeOffer },
]


const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router