<template>
    <div class="headerComp ">
        <div class=" col-12" >
            <div class="col-12">

                <nav class="navbar navbar-expand-lg navbar-light bg-light">
                    <div class="container">
                        <a class="logo" href="/" style="margin-top:30px; margin-bottom:30px;"></a>


                        <div class="menu">
                            <a class="info nav-text" href="/">
                                Home
                            </a>
                            <a class="info nav-text" href="/AboutUs">
                                About us
                            </a>
                            <a class="info nav-text" href="/WhatWeDo">
                                What we do
                            </a>
                            <a class="info nav-text" href="/OurProcess">
                                Our Process
                            </a>
                            <a class="info nav-text" href="/OurPortfolio">
                                Our portfolio
                            </a>
                            <a class="info nav-text" href="/Contact">
                                Contact
                            </a>
                        </div>
                        <div class="menu2">
                            <button  type="button" style="color: gold !important; background:none ; border:none;"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarSupportedContent"
                                    @click="visible=!visible">
                                <span class="material-icons-outlined gold icon-middle" style="font-size:36px;">menu</span>

                            </button>
                            <div class="" :class="!visible?'collapse':''" id="navbarSupportedContent">
                                <ul class="navbar-nav" style="padding:16px; z-index:9999;">

                                    <li class="nav-item2" style="margin-top:60px;">
                                        <router-link to="/AboutUs" class="nav-link" @click="visible=!visible">About Us</router-link>
                                    </li>
                                    <li class="nav-item2" style="margin-top:16px;">
                                        <router-link to="/WhatWeOffer" class="nav-link" @click="visible=!visible"> Bathroom Renovations </router-link>
                                    </li>
                                    <li class="nav-item2" style="margin-top:16px;">
                                        <router-link to="/WhatWeOffer" class="nav-link" @click="visible=!visible">Basement Renovations</router-link>
                                    </li>
                                    <li class="nav-item2" style="margin-top:16px;">
                                        <router-link to="/WhatWeOffer" class="nav-link" @click="visible=!visible">Kitchen Renovations</router-link>
                                    </li>

                                    <li class="nav-item2" style="margin-top:16px;">
                                        <router-link to="/Additions" class="nav-link" @click="visible=!visible">Additions</router-link>
                                    </li>
                                    <li class="nav-item2" style="margin-top:16px;">
                                        <router-link to="/Laneway" class="nav-link" @click="visible=!visible">Laneway Housing</router-link>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </nav>

            </div>
        </div>
     
    </div>
</template>

<script>
    export default {
        name: 'headerComp',
        data() {
            return {
                visible: false

            }
        },
        mounted() {
            this.setFullHeightDiv();
            window.addEventListener('resize', this.setFullHeightDiv);
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.setFullHeightDiv);
        },
        methods: {
            gotoContact() {
                this.$router.push('/contact');
            },
            setFullHeightDiv() {
                const fullHeightDiv = this.$el.querySelector('.navbar-nav');
                console.log("height:", document.documentElement.scrollHeight + 'px');
                fullHeightDiv.style.height = document.documentElement.scrollHeight + 'px';
            }
        }

    };

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!--  background: url('../assets/images/footer.png') no-repeat; -->
<style scoped>
    

    .height110 {
        min-height: 110px;
    }

    .headerComp {
        display: flex;
        height: auto;
        width: 100%;
        
    }
</style>

