<template>
    <div class="home">
        <div class="container">
            <div class="row  bg">
                <div class="col-6" style="padding-left:30px;">
                    <a class="logo" href="/"></a>
                </div>
                <div class="col-6" style="padding-right:30px; padding-top:40px; text-align:right;">
                    <div class="ourportfolio">
                        <span class="fa-solid fa-arrow-left" style="color: #f6d477; margin-right:16px;"></span>
                        <a class="title back-arrow" style="margin-left:0px;" href="/OurPortfolio">Our Portfolio</a>
                    </div>

                </div>

                <div class="col-12 row">
                    <div class="title" style="text-align:left; margin-top:30px; margin-bottom:30px; font-size:50px;">{{obj.title}}</div>


                </div>
                <div class="row" style="position:relative;clear:both;">
                    <div class="title price">Cost: {{obj.price}}</div>
                </div>
                <div class="row content" >
                    <div class="col-6 text-center">
                        <img src="../assets/images/BLANDFORD/BLANFORD_AVE_IMAGE_1.png" style="width:100%; max-width:400px; margin:auto;" class="img-responsive" />
                    </div>
                    <div class="col-6">
                        <div class="desc" style="text-align:right;">
                            This project - one of the largest that we've undertaken in recent years - saw our team tasked with transforming an old 2-storey, 1,400 sq ft detached house, which included raising the house, completely rebuilding the foundation, and adding a new storey addition with basement.
                        </div>
                    </div>

                </div>

                <div class="col-12 row" style="display:flex; margin:32px 0px; padding:0px 10px !important; ">
                    <div class="col-6 col-md-4 lightBg">
                        <div class="title text" style="">Completion Date:</div>
                        <div class="text2">{{obj.completeDate}}</div>

                    </div>
                    <div class="col-6 col-md-4 lightBg">
                        <div class="title text">Property type :</div>
                        <div class="text2">{{obj.type}}</div>

                    </div>

                    <div class="col-12 col-md-4 lightBg">
                        <div class="title text">Duration:</div>
                        <div class="text2">{{obj.duration}}</div>

                    </div>
                </div>

                <div class="row content"  >

                    <div class="col-6" style="vertical-align:middle;">
                        <div class="desc" style="text-align:left;">
                            To start off, we completely gutted the interior of the house, removing all walls, appliances, fixtures, etc. This was followed by raising the house by 3 feet and constructing completely new concrete footings, and a new concrete foundation, underneath the existing house, as well as in the spot where the new addition was set to go. The electrical work throughout the house was modernized and updated to 200-amp service from the previous 60-amp fuse box service, and also updated it from the old knob-and-tube style. Brand new plumbing was installed throughout all levels of the house, with high-quality copper pipes replacing the original lead pipes, and a new 3/4 inch water supply line, also upgraded from lead to copper. The original house only had one 3-piece bathroom, and by the time we were finished, it had 3 full bathrooms plus an ensuite, each one containing high-quality fixtures, custom-build vanities, and luxurious heated flooring.
                        </div>
                    </div>
                    <div class="col-6 text-center" style="vertical-align:top;">
                        <img src="../assets/images/BLANDFORD/BLANFORD_AVE_IMAGE_2.png" style="width:100%; max-width:400px; margin:auto;" class="img-responsive" />
                    </div>
                </div>


                <div class="row content" >
                    <div class="col-6 text-center">
                        <img src="../assets/images/BLANDFORD/BLANFORD_AVE_IMAGE_3.png" style="width:100%; max-width:400px; margin:auto;" class="img-responsive" />
                    </div>
                    <div class="col-6" style="vertical-align:bottom;">
                        <div class="desc col-6" style="text-align:right;">
                            As we rebuilt the walls, we installed sheep wool insulation throughout the home, as we feel (and the homeowners agreed) that this is a much better option than fiberglass insultation. New drywall and plaster were applied, and the height of the main ceiling was raised from 8-feet to 10-feet, providing much more room to enjoy the solid hardwood plank flooring that was laid. 65 new 4-inch pot lights were installed, and all windows (which were 1-pane) were replaced with 3-pane glass, which makes them far more energy efficient. The new front and back doors also contained 3-pane glass.
                        </div>
                    </div>

                </div>

                <div class="row content" >


                    <div class="desc col-6" style="text-align:left;">
                        We were especially proud of our work in the kitchen, starting with custom-build cabinets throughout, including ones that encase the stainless-steel gas oven. The new refrigerator includes panel-ready doors, and a walk-in pantry provides an abundance of space for non-perishable food and other items. A major focal point is the gorgeous 32 linear feet custom backsplash, which is constructed with 8x4 feet marble slabs which fit perfectly with the 3/4 inch quartz countertops throughout.
                    </div>

                    <div class="col-6 text-center">
                        <img src="../assets/images/BLANDFORD/BLANFORD_AVE_IMAGE_4.png" style="width:100%; max-width:400px; margin:auto;" class="img-responsive" />
                    </div>
                </div>

                <div class="row content">
                    <div class="col-12 desc">
                        The final steps before completion included a new custom laundry ensuite on the main floor; a built-in fireplace in a newly-built wall; Cat6 wiring throughout the home, as well a 'smart rack' that controls the lights and electricity throughout the entire home; and a new open-rise staircase with frameless glass railings.
                    </div>
                   
                </div>

                <div class="col-12" style="position:relative; margin-top:40px;">

                    <div class="nextpage">
                        <a class="title back-arrow" style="margin-right:0px;" href="/PortfolioDufferin">Dufferin & Eglinton</a>
                        <span class="fa-solid fa-arrow-right" style="color: #f6d477; margin-left:16px; "></span>

                    </div>
                    
                    
                </div>
            </div>
          
            
 
        </div>
    </div>
</template>
 
<script>
    export default {
        name: 'Portfolio',
        data() {

            return {
              
                obj: {
                    title: 'Blandford Avenue - Detached House Raised 3 Feet, New Foundation, Storey Addition w/ Basement',
                 
                    completeDate: 'May 2021',
                    type: 'Residential',
                    price: '$1,100,000',
                    duration: '5 Months',
                    desc: '',
                    next: ''
                }

            }
        },

        mounted() {
         
        },
        methods: {
             
        }
        
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    @import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css'; 

    .bg {
        background: linear-gradient( rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.9) ), url('../assets/images/ouporfiliobg.jpg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        padding-top: 80px;
        padding-bottom: 30px;
    }
    .lightBg {
      
    }

    .lightBg .text{font-size: 20px !important; padding: 16px 0px 16px 16px;}
    .lightBg .text2{font-size: 18px !important; color:white; padding:0 0 16px 16px;}

    .price{color:white; font-size:30px !important;}
    .desc{color:#ccc; font-size:16px; line-height:26px; margin-bottom:16px; margin-top: 16px; display:table-cell; vertical-align:middle;}
     
    .back-arrow{ color: #ccc !important; font-size:26px; text-decoration:none; display:block; margin:12px;}
    .back-arrow:hover{color: #f6d477 !important;}

     .content{display:table; margin-top:32px;}
     .content div {display:table-cell; }

    .ourportfolio { display:flex; float:right;}
    .ourportfolio span{font-size:20px; line-height:60px;}

    .nextpage { display:flex; float:right;}
    .nextpage span{font-size:20px; line-height:60px;}

    .bottom-right {
        position: absolute;
        bottom: 16px;
        right: 16px;
        font-size: 30px !important;
        color: #f6d477;
        line-height: 60px;
    }


    .top-left2 { position: absolute; top: 32px; left: 32px;  }
   

    .bottom-right2{position:absolute; bottom: 16px; right: 16px; font-size: 30px !important; color: #f6d477; line-height:60px; display:flex; cursor:pointer;}
    .bottom-right2 span{font-size:20px; line-height:60px;}


    @media only screen and (max-width: 900px) {
      
         .top-left2 { position: absolute; top: 8px; left: 16px; display:flex; }
        .bottom-right2{font-size: 22px !important; line-height:50px;}
           .bottom-right2 span{font-size:20px; line-height:50px;}
    }
    @media only screen and (max-width: 600px) {
        .back-arrow{font-size:20px;}
        .ourportfolio span{font-size:16px; line-height:55px;}

        .bottom-right {
            font-size: 20px !important; line-height: 40px;
        }
         
         .top-left2 { position: absolute; top: 8px; left: 16px; display:flex; }
          .bottom-right2{font-size: 14px !important; line-height:40px;}
          .bottom-right2 span{font-size:14px; line-height:40px;}
    }
</style>

