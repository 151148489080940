<template>
    <transition name="modal-fade">
        <div class="modal-overlay" @click="$emit('close-modal')">
            <div class="modal-content" @click.stop>

                <div class="row">

                    <div class="col-12 text-center">
                        <div class="title" style="font-size:16px;">
                            {{msg}}
                        </div>

                    </div>
                    
                </div>
                 
            </div>

        </div>
    </transition>

</template>

<script>
 
    export default {
        props: {
            message: { default: 'Message Sent!'}
        },
        data() {
            return {
                msg: this.message
            }
         
        },
        methods: {
            showMessage(message, timer) {

                let _this = this;
                this.msg = (message === null || message === undefined || message == '') ? this.message : message;

                setTimeout(() => {
                    _this.$emit('close-modal');
                }, timer);
            }
        }
    }
</script>

<style scoped>
    .modal-overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
       
    }

    .modal-content {
        background-color: rgba(0, 0, 0, 0.5);
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 0 10px black;
        max-width: 400px;
        border: 1px solid #f6d477;
    }
      
    .modal-fade-enter,
    .modal-fade-leave-to {
        opacity: 0;
    }

    .modal-fade-enter-active,
    .modal-fade-leave-active {
        transition: opacity 0.5s ease;
    }

     
    .col-12{height:max-content;}
</style>