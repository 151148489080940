import { createApp } from 'vue';
import router from './router';
import App from './App.vue';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery';
import './style/custom.css';
import './js/custom.js';

import Axios from 'axios';





const app = createApp(App);

app.config.globalProperties.$axios = Axios;

app.use(router);

app.mount('#app');

const $ = require('jquery');
window.$ = $;
