<template>
    <div class="home">
        <div class="container">
            <div class="row  bg">
                <div class="col-6" style="padding-left:30px;">
                    <a class="logo" href="/"></a>
                </div>
                <div class="col-6" style="padding-right:30px; padding-top:40px; text-align:right;">
                    <div class="ourportfolio">
                        <span class="fa-solid fa-arrow-left" style="color: #f6d477; margin-right:16px;"></span>
                        <a class="title back-arrow" style="margin-left:0px;" href="/OurPortfolio">Our Portfolio</a>
                    </div>

                </div>

                <div class="col-12 row">
                    <div class="title" style="text-align:left; margin-top:30px; margin-bottom:30px; font-size:50px;">{{obj.title}}</div>


                </div>
                
                <div class="row ">
                    <div class="col-6">
                        <div class="title price">Cost: {{obj.price}}</div>
                    </div>

                    <div class="col-6 text-center">
                        <img src="../assets/images/DUFFERIN/DUFFERIN_IMAGE_1.png" style="width:100%; max-width:400px; margin:auto;" class="img-responsive" />
                    </div>


                </div>

                <div class="col-12 row" style="display:flex; margin:32px 0px; padding:0px 10px !important; ">
                    <div class="col-6 col-md-4 lightBg">
                        <div class="title text" style="">Completion Date:</div>
                        <div class="text2">{{obj.completeDate}}</div>

                    </div>
                    <div class="col-6 col-md-4 lightBg">
                        <div class="title text">Property type :</div>
                        <div class="text2">{{obj.type}}</div>

                    </div>

                    <div class="col-12 col-md-4 lightBg">
                        <div class="title text">Duration:</div>
                        <div class="text2">{{obj.duration}}</div>

                    </div>
                </div>

                <div class="row content">
                    <div class="col-6 text-center">
                        <img src="../assets/images/DUFFERIN/DUFFERIN_IMAGE_2.png" style="width:100%; max-width:400px; margin:auto;" class="img-responsive" />
                    </div>
                    <div class="col-6">
                        <div class="desc" style="text-align:right;">
                            We frequently get requests from our clients to take an existing bungalow - by far the most common and affordable style of house in Toronto and other parts of the GTA - and completely transform it from a simple one-storey house to an opulent, luxurious home that can be lived in comfortably for years and decades to come. For this particular project in the Dufferin & Eglinton area, we were working on a 1,000 sq ft bungalow whose owners wanted to renovate the main floor, as well as build a 400 sq ft addition at the back of the house.
                        </div>
                    </div>

                </div>


                <div class="row content" >
                    <div class="col-12 text-center">
                        <img src="../assets/images/DUFFERIN/DUFFERIN_IMAGE_3.png" style="width:100%; max-width:800px; margin:auto;" class="img-responsive" />
                    </div>
                    

                </div>
 
                <div class="row content">
                    <div class="col-12 desc">
                        The main floor and existing walls were fully demolished, and the electrical wiring was updated from knob and tube to a more modern style that's safer, more dependable, and much more palatial. The plumbing - which was decades old and in dire need of an upgrade - was updated from clay pipes to new-and-dependable PVC pipes, which is an enormous improvement in virtually every way. As the walls in the existing main floor and new addition were completed, they were accompanied by new drywall and plaster, and the ceiling height was raised from 8 to 10 feet tall - a small-yet-grand improvement that provides an abundance of extra space and really opens up the house. Throughout the main floor and addition, we also installed 35 new 4-inch pot lights; a new main door and back door, both made of fibreglass with glossy finishes; new 6-inch solid hardwood planks to replace the weathered and cheap linoleum; custom cabinets in the kitchen and brand-new stainless-steel appliances, plus a custom marble backsplash; 2 full bedrooms (master and guest); one 3-piece bathroom and a laundry ensuite.
                    </div>
                   
                </div>

                <div class="col-12" style="position:relative; margin-top:40px;">

                    <div class="nextpage">
                        <a class="title back-arrow" style="margin-right:0px;" href="/PortfolioBloor">Bloor & Kipling</a>
                        <span class="fa-solid fa-arrow-right" style="color: #f6d477; margin-left:16px; "></span>

                    </div>
                    
                    
                </div>
            </div>
          
            
 
        </div>
    </div>
</template>
 
<script>
    export default {
        name: 'Portfolio',
        data() {

            return {
                
                obj: {
                    title: 'Dufferin & Eglinton - Main Floor Bungalow Renovation + Back Addition',
                   
                    completeDate: 'July 2020',
                    type: 'Residential',
                    price: '$360,000',
                    duration: '4 Months',
                    desc: '',
                    next: ''
                }

            }
        },

        mounted() {
          
        },
        methods: {

           
            

        }
        
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    @import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css'; 

    .bg {
        background: linear-gradient( rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.9) ), url('../assets/images/ouporfiliobg.jpg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        padding-top: 80px;
        padding-bottom: 30px;
    }
    .lightBg {
      
    }

    .lightBg .text{font-size: 20px !important; padding: 16px 0px 16px 16px;}
    .lightBg .text2{font-size: 18px !important; color:white; padding:0 0 16px 16px;}

    .price{color:white; font-size:30px !important;}
    .desc{color:#ccc; font-size:16px; line-height:26px; margin-bottom:16px; margin-top: 16px; display:table-cell; vertical-align:middle;}
     
    .back-arrow{ color: #ccc !important; font-size:26px; text-decoration:none; display:block; margin:12px;}
    .back-arrow:hover{color: #f6d477 !important;}

     .content{display:table; margin-top:32px;}
     .content div {display:table-cell; }

    .ourportfolio { display:flex; float:right;}
    .ourportfolio span{font-size:20px; line-height:60px;}

    .nextpage { display:flex; float:right;}
    .nextpage span{font-size:20px; line-height:60px;}

    .bottom-right {
        position: absolute;
        bottom: 16px;
        right: 16px;
        font-size: 30px !important;
        color: #f6d477;
        line-height: 60px;
    }


    .top-left2 { position: absolute; top: 32px; left: 32px;  }
   

    .bottom-right2{position:absolute; bottom: 16px; right: 16px; font-size: 30px !important; color: #f6d477; line-height:60px; display:flex; cursor:pointer;}
    .bottom-right2 span{font-size:20px; line-height:60px;}


    @media only screen and (max-width: 900px) {
      
         .top-left2 { position: absolute; top: 8px; left: 16px; display:flex; }
        .bottom-right2{font-size: 22px !important; line-height:50px;}
           .bottom-right2 span{font-size:20px; line-height:50px;}
    }
    @media only screen and (max-width: 600px) {
        .back-arrow{font-size:20px;}
        .ourportfolio span{font-size:16px; line-height:55px;}

        .bottom-right {
            font-size: 20px !important; line-height: 40px;
        }
         
         .top-left2 { position: absolute; top: 8px; left: 16px; display:flex; }
          .bottom-right2{font-size: 14px !important; line-height:40px;}
          .bottom-right2 span{font-size:14px; line-height:40px;}
    }
</style>

