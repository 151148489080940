<template>
    <transition name="modal-fade">
        <div class="modal-overlay" @click="$emit('close-modal')">
            <div class="modal-content" @click.stop>

                <form class="row" style="height:100%;">

                    <div class="col-6 left-bg col-md-0 needToHide">
                        <img src="../../assets/images/bookcover.png" style="width:80%; max-width:450px; margin:40px 0;" />
                    </div>

                    <div class="col-md-6 row col-12" style="background-color:white; padding:0px; text-align:left;">
                        <div class="col-12 title text-center" style="background-color: #D5BD7A; color:black; padding:10px; font-size:20px; line-height: 35px;">Please fill out the information below to get access to the free eBook</div>

                        <div class="col-12" style="text-align:left;">
                            <label class="title" style="font-size:16px; color:black;">First Name</label>
                            <input type="text" class="form-control" v-model="info.firstname">
                        </div>

                        <div class="col-12" style=" text-align: left;">
                            <label class="title" style="font-size:16px; color:black;">Last Name</label>
                            <input type="text" class="form-control" v-model="info.lastname">
                        </div>
                        <div class="col-12" style=" text-align: left;">
                            <label class="title" style="font-size:16px; color:black;">Email Address</label>
                            <input type="text" class="form-control" v-model="info.email">
                        </div>
                        <div class="col-12" style=" text-align: left;">
                            <label class="title" style="font-size:16px; color:black;">Phone Number</label>
                            <input type="text" class="form-control" v-model="info.phone">
                        </div>
                        <div class="col-12">
                          
                            <label for="email" style="display:block; text-indent:-15px; padding-left:15px;">  <input type="checkbox" id="email" v-model="info.contactByEmail" />contact me by Email</label>
                        </div>
                        <div class="col-12" >
                          
                            <label for="phone" style="display:block; text-indent:-15px; padding-left:15px;">  <input type="checkbox" id="phone" v-model="info.contactByPhone" />contact me by Phone number</label>
                        </div>
                        <div class="col-12 text-center">
                            <img src="../../assets/images/btn_getbook.png" style="width:90%; max-width:270px; margin:auto; cursor:pointer;"  @click="save"/>
                        </div>

                        <br /><br />
                    </div>
                    

                </form>
              
            </div>

        </div>
    </transition>

</template>

<script>
import axios from "axios";

    export default {
        data() {
            return {
                info: {
                    firstname: "",
                    lastname: "",
                    email: "",
                    phone: "",
                    contactByEmail: true,
                    contactByPhone: true,
                    FromContact: false,
                    Message:""
                }
            }
        },
        methods: {
            save() {
                
                let _this = this;
                var url = '/api';
                axios.post(url, _this.info).then(function (response) {
                        
                    if (response.data.Code == 0) {
                        _this.info = {
                            firstname: "",
                            lastname: "",
                            email: "",
                            phone: "",
                            contactByEmail: true,
                            contactByPhone: true,
                            FromContact: false,
                            Message: ""
                        }
                        _this.$emit('save');
                        
                    } else {

                        alert(response.data.Msg);
                    }
                       
                    })
                    .catch(function (error) {
                        console.log(error);
                    });

                
            }
        }
    }
</script>

<style scoped>
    .modal-overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        background-color: #00000088;
    }

    .modal-content {
        text-align: center;
        background-color: #132338;
         
        width: 90%;
        max-width:700px;
        
        
    }
      
    .modal-fade-enter,
    .modal-fade-leave-to {
        opacity: 0;
    }

    .modal-fade-enter-active,
    .modal-fade-leave-active {
        transition: opacity 0.5s ease;
    }

    .left-bg {
        background: linear-gradient( rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.9) ),url('../../assets/images/popupleftbg.png') no-repeat;
        background-position: center;
        background-size: cover;
        height: 100% !important;
        display: flex;
        align-items: center;
        justify-content: center;
       
    }
    .form-control{border: 2px black solid; border-radius:6px; height:34px;}

    input[type='checkbox'] {
        width: 15px;
        height: 15px;
        padding: 0;
        margin-right: 8px;
        vertical-align: middle;
        position: relative;
        top: -1px;
    }
    .col-12{height:max-content;}

    .needToHide{display:block;}

    @media only screen and (max-width: 768px) {
        .needToHide{display:none !important;}
    }
</style>